import { styled } from '@mui/material'
import { Box } from '@stationkim/front-ui'

export const InnerTableContainer = styled(Box)(({ theme }) => ({
  padding: '0',
  width: '100%',

  '& .BaseTable__header': {
    height: '36px !important',
  },
  '& .BaseTable__header-row': {
    height: '36px !important',
    backgroundColor: theme.palette.grey[50] + '!important',
    boxShadow: 'inset 0px 4px 4px -1px ' + theme.palette.grey[200],
  },
  '& .BaseTable__header-cell': {
    padding: '0px 16px',
    height: '36px',
  },
  '& .BaseTable__row': {
    backgroundColor: theme.palette.grey[50],
  },
}))
