import { useAtomValue } from 'jotai'
import { atomWithHash } from 'jotai-location'
import { OrderTracking as OldOrderTracking } from './oldOrderTracking/orderTracking'
import { OrderTracking as NewOrderTracking } from './orderTracking'
import { useNavigate } from 'react-router-dom'
import { useFilter, usePagination } from '@/main/hooks'
import { useOrderTracking } from './hooks/useOrderTracking'

export const isDealerOrderHash = atomWithHash('isDealerOrder', true)
const filterAtom = atomWithHash<object>('orderItemsFilter', {})
const paginationAtom = atomWithHash<object>('orderItemsPagination', null)

const NewIntegration = () => {
  const navigate = useNavigate()
  const { filter, updateFilter } = useFilter({ filterAtom })
  const { pagination, goToPage, setPageSize } = usePagination({
    paginationAtom,
    initialValue: {
      page: 1,
      pageSize: 200,
    },
    outputMap: {
      page: 'pagina',
      pageSize: 'qtdLinhas',
    },
  })

  const { order, isLoading, isItemsLoading } = useOrderTracking({ filter, pagination })

  const isRecharge = true

  const handleReturn = () => navigate('/pedidos')
  return (
    <NewOrderTracking
      handleReturn={handleReturn}
      isLoading={isLoading}
      isRecharge={isRecharge}
      order={order}
      items={{
        filter,
        goToPage,
        setPageSize,
        updateFilter: (data: any) => updateFilter({ pesquisa: data.filterValue }),
        isLoading: isItemsLoading,
        orderItems: order?.orderItems,
      }}
    />
  )
}

export const OrderTracking = () => {
  const isDealer = useAtomValue(isDealerOrderHash)
  if (isDealer) return <OldOrderTracking />
  else return <NewIntegration />
}
