import { useMemo } from 'react'
import { Theme, useTheme } from '@mui/material'
import { Box, Icon, Tooltip } from '@stationkim/front-ui'
import { faBarcode, faHandHoldingDollar, faWallet } from '@fortawesome/free-solid-svg-icons'
import { faPix } from '@fortawesome/free-brands-svg-icons'
import { useAtomValue } from 'jotai'
import { ETipoPagamento } from '@/main/enums'
import { AccountPersistentStore } from '@/main/store'

interface OrderTypeProps {
  paymentType: ETipoPagamento
}

const getPaymentStyle = (paymentType: ETipoPagamento, walletName: string, theme: Theme) => {
  const paymentMap = {
    [ETipoPagamento.Boleto]: { color: theme.palette.common.black, icon: faBarcode, title: 'Boleto' },
    [ETipoPagamento.CarteiraOperadora]: { color: theme.palette.common.black, icon: null, title: 'Carteira Operadora' },
    [ETipoPagamento.CarteiraSK]: { color: theme.palette.common.black, icon: faWallet, title: walletName },
    [ETipoPagamento.Gratuito]: { color: theme.palette.common.black, icon: faHandHoldingDollar, title: 'Gratuito' },
    [ETipoPagamento.Pix]: { color: theme.palette.success.dark, icon: faPix, title: 'Pix' },
  }
  return paymentMap[paymentType]
}

export const PaymentType = ({ paymentType }: OrderTypeProps) => {
  const theme = useTheme()
  const account = useAtomValue(AccountPersistentStore)
  const paymentStyle = useMemo(
    () => getPaymentStyle(paymentType, account.resale.digitalAccountName || 'Carteira Digital', theme),
    [theme, paymentType, account],
  )

  return (
    <Tooltip title={paymentStyle.title} placement='top'>
      <Box
        sx={{
          backgroundColor: theme.palette.grey[100],
          color: paymentStyle.color,
          borderRadius: '50%',
          height: '24px',
          width: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '14px',
        }}
      >
        <Icon icon={paymentStyle.icon} />
      </Box>
    </Tooltip>
  )
}
