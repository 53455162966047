import { useMemo } from 'react'

const mock = [
  {
    id: '1',
    name: 'Anders Seabert Hookerford Williams Third',
    src: '',
    notificationCount: 4,
  },
  {
    id: '2',
    name: 'Gabriell Sealy',
    src: '',
    notificationCount: 22,
  },
  {
    id: '3',
    name: 'Samara Foxten',
    src: 'http://dummyimage.com/162x100.png/dddddd/000000',
    notificationCount: 5,
  },
  {
    id: '4',
    name: 'Sherrie Innman',
    src: 'http://dummyimage.com/157x100.png/dddddd/000000',
    notificationCount: 13,
  },
  {
    id: '5',
    name: 'Hewett Abrahams',
    src: 'http://dummyimage.com/127x100.png/dddddd/000000',
    notificationCount: 4,
  },
  {
    id: '6',
    name: 'Gerardo Mingus',
    src: '',
    notificationCount: 19,
  },
  {
    id: '7',
    name: 'Hercule Longea',
    src: '',
    notificationCount: 4,
  },
  {
    id: '8',
    name: 'Cybil Rainey',
    src: 'http://dummyimage.com/153x100.png/cc0000/ffffff',
    notificationCount: 5,
  },
  {
    id: '9',
    name: 'Michelle Schneidar',
    src: 'http://dummyimage.com/211x100.png/dddddd/000000',
    notificationCount: 7,
  },
  {
    id: '10',
    name: 'Trixi Seldner',
    src: '',
    notificationCount: 18,
  },
]

export const useResaleUsers = () => {
  const organizedData = useMemo(() => {
    return mock.sort((a, b) => {
      if (a.notificationCount > b.notificationCount) return -1
      if (a.notificationCount < b.notificationCount) return 1
      return 0
    })
  }, [])

  return {
    users: organizedData,
  }
}
