import { keyframes, styled } from '@mui/material'
import { Box, Icon } from '@stationkim/front-ui'

export const SummaryOrderDataContainer = styled(Box)(({ theme }) => ({
  border: '1px solid ' + theme.palette.grey[300],
  padding: '8px',
}))

export const ContactContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '250px',
  overflow: 'hidden',
}))

export const ContactTypography = styled('a')(({ theme }) => ({
  fontSize: '.875rem',
  margin: '10px 0',
  fontWeight: 500,
  whiteSpace: 'nowrap',
  display: 'flex',
  gap: '8px',
  '& a': {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    width: '100%',
  },
}))

export const FieldContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '70px auto',
  alignItems: 'center',
  gap: '4px',
  fontSize: '.875rem',
  '&:last-of-type': {
    marginTop: '8px',
  },
}))

const spinning = keyframes`
  from{ transform: rotate(0deg) }
  to{ transform: rotate(360deg) }
`
export const LoadingIcon = styled(Icon)(() => ({
  animation: `${spinning} .9s linear infinite`,
}))
