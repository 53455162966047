import { faArrowRightToBracket, faCircleNotch, faX } from '@fortawesome/free-solid-svg-icons'
import {
  Box,
  Button,
  CopyableField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  formatMoney,
  Grid,
  Icon,
  Typography,
  UserInfo,
} from '@stationkim/front-ui'
import { ContactContainer, ContactTypography, FieldContainer, LoadingIcon, SummaryOrderDataContainer } from './styles'
import { OrderType } from '../orderType/orderType'
import { IOrder } from '../../hooks/useOrderList'
import { EStatusCompra } from '@/main/enums'
import { useOrdersFiles } from '../../hooks/useOrdersFiles'

interface SummaryDialogProps {
  open: boolean
  order: IOrder
  onClose: () => void
}

const downloadIcon = <Icon icon={faArrowRightToBracket} sx={{ transform: 'rotate(90deg)' }} />

const OrderInfo = ({
  title,
  value,
  gridConfig = { xs: 12, sm: 6, md: 3 },
}: {
  title: string
  value: string | React.ReactNode
  gridConfig?: { xs?: number | 'auto'; sm?: number | 'auto'; md?: number | 'auto' }
}) => {
  return (
    <Grid item {...gridConfig}>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 700 }}>{title}</Typography>
      {typeof value === 'string' ? (
        <Typography
          sx={(theme) => ({
            fontSize: '0.875rem',
            fontWeight: 500,
            color: theme.palette.grey[700],
          })}
        >
          {value}
        </Typography>
      ) : (
        value
      )}
    </Grid>
  )
}
const noValueText = 'N/A'
export const SummaryDialog = ({ open, onClose, order }: SummaryDialogProps) => {
  const { getBrokerOrderLayout, isLoadingOrderLayout } = useOrdersFiles({
    orderNumber: order?.orderNumber,
    orderId: order?.id,
  })
  const isLayoutAvailable = order.orderStatus > EStatusCompra.AguardandoPagamento
  return (
    <Dialog open={open} maxWidth='md' onClose={onClose}>
      <DialogTitle sx={{ marginBottom: '32px' }}>
        {order.orderNumber}
        <Button onClick={onClose} sx={{ minWidth: 'unset' }} disableRipple>
          <Icon icon={faX} />
        </Button>
      </DialogTitle>
      <DialogContent>
        <SummaryOrderDataContainer sx={{ marginBottom: '32px' }}>
          <Grid container columnSpacing='32px' rowSpacing='32px'>
            <OrderInfo title='Nº pedido' value={order.orderNumber} />
            <OrderInfo title='Data' value={order.orderDate} />
            <OrderInfo title='Operadora' value={order.operator} />
            <OrderInfo title='Reserva' value={<UserInfo name='Não Atribuído' role='' />} />
            <OrderInfo title='Tipo' value={<OrderType type={order.orderType} />} />
            <OrderInfo title='Nº colabs.' value={order.totalItensInOrder} />
            <OrderInfo title='Data para crédito' value={order.creditDate ?? noValueText} />
            <OrderInfo title='Valor total' value={'R$ ' + formatMoney(order.totalValue)} />
          </Grid>
        </SummaryOrderDataContainer>
        <Grid container columnSpacing='32px' rowSpacing='32px'>
          <OrderInfo
            title='Arquivos'
            value={
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Button disabled variant='softText' endIcon={downloadIcon} size='small' sx={{ width: '160px' }}>
                  Layout Colab
                </Button>
                <Button
                  variant='softText'
                  endIcon={isLoadingOrderLayout ? <LoadingIcon icon={faCircleNotch} /> : downloadIcon}
                  size='small'
                  sx={{ width: '160px' }}
                  fullWidth={false}
                  onClick={isLoadingOrderLayout ? null : () => getBrokerOrderLayout()}
                  disabled={!isLayoutAvailable}
                >
                  Layout Pedido
                </Button>
              </Box>
            }
            gridConfig={{ xs: 12, sm: 'auto' }}
          />
          <OrderInfo
            title='Dados da operadora'
            value={
              <ContactContainer>
                <ContactTypography>
                  Site:{' '}
                  {order.operatorSite ? (
                    <a href={order.operatorSite} title={order.operatorSite} target='_blank' rel='noreferrer'>
                      {order.operatorSite}
                    </a>
                  ) : (
                    noValueText
                  )}
                </ContactTypography>
                <ContactTypography sx={{ fontSize: '.875rem', margin: '8px 0', fontWeight: 500 }}>
                  Contato:{' '}
                  {order.operatorEmail ? (
                    <a href={'mailto:' + order.operatorEmail} title={order.operatorEmail}>
                      {order.operatorEmail}
                    </a>
                  ) : (
                    noValueText
                  )}
                </ContactTypography>
              </ContactContainer>
            }
            gridConfig={{ xs: 12, sm: 4 }}
          />
          <OrderInfo
            title='Dados de acesso'
            gridConfig={{ xs: 12, sm: 4 }}
            value={
              <>
                <FieldContainer title={order.operatorUser}>
                  Usuário: <CopyableField disabled value={order.operatorUser} />
                </FieldContainer>
                <FieldContainer>
                  Senha: <CopyableField disabled value={order.operatorPassword} isPassword />
                </FieldContainer>
              </>
            }
          />
        </Grid>
        <Divider sx={{ margin: '24px 0 ' }} />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'start' }}>
        <Button variant='outlined' color='error' size='medium' disabled>
          Cancelar Pedido
        </Button>
        <Button variant='outlined' sx={{ filter: 'grayscale(100)' }} size='medium' disabled>
          Retirar reserva
        </Button>
      </DialogActions>
    </Dialog>
  )
}
