import styled from '@emotion/styled'

export const Form = styled.form`
  margin-top: 40px;

  > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 4px;
      font-weight: 600;
    }

    input {
      border: 1px solid #9e9e9e;
      width: 100%;
      height: 48px;
      border-radius: 4px;
      padding: 0 8px;
    }

    span {
      color: var(--color-status-02);
      font-size: 12px;
      letter-spacing: 0;
      margin-top: 4px;
      height: 15px;
      white-space: nowrap;
    }
  }
`
