export const ALLOWED_LOGIN_ROLES = 'resale'
export const FIVE_SECONDS = 5000
export const TEN_SECONDS = 10000
export const NULL_ID = '00000000-0000-0000-0000-000000000000'
export const TEN_MB = 1000000000000000000000000000000000000
export const DATE_TO_IGNORE = '0001-01-01'
export const TEMP_OPERATOR_ID = '7e17d3a1-4f26-443f-b8ff-0a4a227e2758'
export const ABBREVIATED_MONTHS = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

export const BENEFIT_TYPES = {
  1: 'Vale Transporte Eletrônico',
  2: 'Vale Refeição',
  3: 'Vale Alimentação',
  4: 'Vale Combustível',
  5: 'Vale Transporte Papel',
  TRANSPORT: 1,
  FOOD: 2,
  SNACK: 3,
  FUEL: 4,
  TRANSPORT_PAPER: 5,
}

export const FORM_MODE = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
}

export const NOTIFICATION_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  1: 'success',
  2: 'error',
  3: 'warning',
}

export const BREAK_POINTS = {
  sm: '575px',
  md: '767px',
  lg: '991px',
  xl: '1199px',
  xxl: '1599px',
}

export const CARD_TYPES = {
  1: 'Vale Transporte Eletrônico',
  TRANSPORT: 1,
}

export const CARD_STATUS = {
  ACTIVE: 1,
  1: 'Ativo',
  INACTIVE: 2,
  2: 'Inativo',
  BLOCKED: 3,
  3: 'Bloqueado',
}

export const COMPANY_DOCUMENT_TYPE = {
  1: 'Cadastro Social',
  2: 'Cartão CNPJ',
  3: 'CAGED (Acima de 20 funcionários)',
  4: 'Formulário ISSQN (assinado por representante legal)',
  SOCIAL_REGISTER: 1,
  CNPJ_CARD: 2,
  CAGED: 3,
  ISSQN_FORM: 4,
}

export const COMPANY_STATUS = {
  0: 'Criado',
  1: 'Aguardando aprovação',
  2: 'Aprovado',
  3: 'Reprovado',
  4: 'Aguardando migração',
  5: 'Migrando',
  6: 'Erro na migração',
  7: 'Aguardando aprovação do operador',
  8: 'Aguardando sincronismo',
  9: 'Erro de sincronismo',
  CREATED: 0,
  WAITING_APPROVAL: 1,
  APPROVED: 2,
  DISAPPROVED: 3,
  WAITING_MIGRATION: 4,
  MIGRATING: 5,
  MIGRATION_ERROR: 6,
  WAITING_OPERATOR: 7,
  SYNCING: 8,
  SYNC_ERROR: 9,
}

export const MONTHS = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
]

export const NUMBER_OF_BENEFICIARIES = {
  1: 'Individual/Autônomo',
  2: '2 a 10 colaboradores',
  3: '11 a 50 colaboradores',
  4: '51 a 100 colaboradores',
  5: '101 a 500 colaboradores',
  6: 'Mais de 500 colaboradores',
}

export const ORDER_TYPES = {
  1: 'Primeira Via',
  2: 'Segunda Via',
  3: 'Recarga',
  5: 'Broker',
  FIRST_COPY: 1,
  SECOND_COPY: 2,
  RECHARGE: 3,
  BROKER: 5,
}

export const ORDER_STATUS = {
  0: 'Rascunho',
  1: 'Efetivando',
  3: 'Aguardando Pagamento',
  4: 'Aguardando Processamento',
  5: 'Em Processamento',
  6: 'Processamento operadora',
  10: 'Disponível para Retirada',
  11: 'Enviando',
  15: 'Concluído',
  16: 'Cancelado',
  17: 'Cancelando',
  DRAFT: 0,
  EFFECTING: 1,
  WAITING_PAYMENT: 3,
  WAITING_FOR_PROCESSING: 4,
  PROCESSING: 5,
  RUNNING: 6,
  AVAILABLE_PICKUP: 10,
  DELIVERING: 11,
  FINISHED: 15,
  CANCELED: 16,
  CANCELING: 17,
}

export type ORDER_STATUS_KEY = keyof typeof ORDER_STATUS

export const ORDER_ITEMS_STATUS = {
  DRAFT: 0,
  RUNNING: 0,
  CALCULATED_BALANCE_PROJECTION: 2,
  CREATED_CARD: 4,
  FINISHED: 10,
  CANCELED: 11,
  CARD_BLOCKED_AND_REFUNDED: 12,
  CARD_BLOCKED: 13,
}

export const ORDER_PAYMENT_TYPE = {
  1: 'Boleto',
  3: 'Cartão de Crédito',
  10: 'Conta Corrente',
  14: 'Pix',
  17: 'Gratuito',
  18: 'Carteira Digital',
  SLIP: 1,
  DEBIT_CARD: 2,
  CREDIT_CARD: 3,
  TRANSFER: 4,
  DEPOSIT: 5,
  TICKETING_BALANCE: 10,
  PIX: 14,
  FREE: 17,
  ACCOUNT_BALANCE: 18,
}

export const ORDER_METHOD = {
  0: 'Manual',
  1: 'Importação',
  MANUAL: 0,
  IMPORT: 1,
}

export const PRODUCT_TYPES = {
  RATES: 1,
}

export const PRODUCT_STATUS = {
  1: 'ATIVO',
  ACTIVE: 1,
  2: 'INATIVO',
  INACTIVE: 2,
  3: 'BLOQUEADO',
  BLOCKED: 3,
}

export const VALUE_TYPE = {
  VALUE: 1,
  PERCENT: 2,
}

export const QUANTITY_TYPE = {
  VALUE: 1,
  QUANTITY: 2,
}

export const TRANSFER_FEE_RANGE_TYPE = {
  QUANTITY: 1,
  PERCENTAGE: 2,
}

export const BENEFIT_TYPE = {
  1: 'Vale Transporte Eletrônico',
  2: 'Vale Alimentação',
  3: 'Vale Refeição',
  4: 'Vale Combustível',
  5: 'Vale Transporte Papel',
  TRANSPORT: 1,
  FOOD: 2,
  SNACK: 3,
  FUEL: 4,
  TRANSPORT_PAPER: 5,
}

export type BENEFIT_TYPE_KEY = keyof typeof BENEFIT_TYPE

export const DESK_FEE_ITEM_TYPE = {
  ADMINISTRATIVE: 1,
  DELIVERY: 2,
  BALANCE_REUSE: 3,
}

export const CALCULATION_TYPE = {
  1: 'Pedido',
  2: 'Colaborador',
  BY_ORDER: 1,
  BY_ITEM: 2,
}

export const ACTIONS = {
  CREATE: '1',
  EDIT: '2',
  DELETE: '3',
}

export const REPORTS_CATEGORIES = {
  ORDERS: 'Pedidos',
  CARDS: 'Cartões',
  // EMPLOYESS: "Colaboradores",
  // BENEFIT: "Benefícios",
  BALANCE: 'Saldo',
  REPASS: 'Repasse',
}

export const ISS_INCIDENCE = {
  MIN_FEE: 1,
  SECOND_COPY_FEE: 2,
  PROCESSING_FEE: 4,
  SUCCESS_FEE: 8,
}

export const PARAMETER_STATUS = {
  1: 'Ativo',
  2: 'Inativo',
  ACTIVE: 1,
  INACTIVE: 2,
}

export const EMPLOYEE_STATUS = {
  0: 'Inativo',
  INACTIVE: 0,
  1: 'Ativo',
  ACTIVE: 1,
}

export const EMPLOYEE_OPERATOR_INTEGRATION = {
  OK: 1,
  1: 'OK',
  RUNNING: 2,
  2: 'Aguardando sincronismo',
  ERROR: 3,
  3: 'Erro de sincronismo',
}
