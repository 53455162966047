import { Checkbox, formatMoney } from '@stationkim/front-ui'
import { OderMenu } from '../components/orderMenu'
import { OrderActions } from '../../../listOrders/components/orderActions/orderActions'
import { StatusChip } from '../../statusChip/statusChip'
import { EStatusCompra } from '@/main/enums'
import { OrderType } from '../../orderType/orderType'

export const getInnerTableColumns = ({ setOrderToDetail }) => {
  return [
    {
      title: '',
      dataKey: 'check',
      key: 'check',
      resizable: false,
      width: 72,
      headerRenderer: () => <Checkbox style={{ paddingLeft: '32px' }} disabled />,
      cellRenderer: () => <Checkbox style={{ paddingLeft: '32px' }} disabled />,
      preventLink: true,
    },
    { title: 'Código do pedido', dataKey: 'orderNumber', key: 'orderNumber', width: 156 },
    { title: 'Operadora', dataKey: 'operator', key: 'operator' },
    {
      title: 'Tipo',
      dataKey: 'orderType',
      key: 'orderType',
      cellRenderer: ({ rowData }) => <OrderType type={rowData.orderType} />,
    },
    {
      title: 'Valor',
      dataKey: 'totalValue',
      key: 'totalValue',
      cellRenderer: ({ rowData }) => 'R$ ' + formatMoney(rowData.totalValue),
    },
    {
      title: 'Status',
      dataKey: 'orderStatus',
      key: 'orderStatus',
      cellRenderer: ({ rowData }) => {
        if (
          [
            EStatusCompra.Efetivando,
            EStatusCompra.ProcessandoFormaPagamento,
            EStatusCompra.AguardandoPagamento,
          ].includes(rowData.purchaseStatus)
        )
          return <StatusChip purchaseStatus={rowData.purchaseStatus} />
        return <StatusChip orderStatus={rowData.orderStatus} />
      },
    },
    { title: 'Pendência', dataKey: 'pendency', key: 'pendency' },
    { title: 'Responsável', dataKey: 'responsibleUser', key: 'responsibleUser' },
    //remove
    {
      title: '',
      dataKey: 'oldActions',
      key: 'oldActions',
      resizable: false,
      width: 64,
      preventLink: true,
      cellRenderer: ({ rowData }) => (
        <OrderActions
          order={{
            orderType: 4, //rowData.orderStatus, solicitar a adicao
            status: rowData.orderStatus,
            id: rowData.id,
            code: rowData.orderNumber,
            companyId: rowData.companyId,
          }}
        />
      ),
    },
    //remove up
    {
      title: '',
      dataKey: 'actions',
      key: 'actions',
      preventLink: true,
      resizable: false,
      width: 64,
      cellRenderer: ({ rowData }) => (
        <OderMenu items={[{ action: () => setOrderToDetail(rowData), title: 'Mais Informações' }]} />
      ),
    },
  ]
}
