import { useTheme } from '@mui/material'
import { faPlay, faPlus } from '@fortawesome/free-solid-svg-icons'
import { CustomIcon } from './styles'
import { Box, CircularProgress, Tooltip } from '@/presentation/components/atoms'
import { useOrderMutations } from '../../hooks/useOrderMutations'
import { useState } from 'react'
import { OrderMenu } from '../orderMenu/orderMenu'
import { useDialogs } from '@/main/hooks'
import { useFilesDownload } from '../../hooks/useFilesDownload'
import { EStatusPedido, ETipoPedido } from '@/main/enums'
import { useOrdersFiles } from '../../../hooks/useOrdersFiles'

interface OrderActionsProps {
  order: any
}

export const OrderActions = ({ order }: OrderActionsProps) => {
  const { setDialog, goBack } = useDialogs()
  const [menuAnchor, setMenuAnchor] = useState()
  const isDealer = order.orderType !== ETipoPedido.RecargaVtBroker
  const isAttendingBroker =
    !isDealer && ![EStatusPedido.Finalizado, EStatusPedido.Cancelado, EStatusPedido.Cancelando].includes(order.status)
  const isWaitingProcess = order.status === EStatusPedido.AguardandoProcessamento
  const shouldShowPlusIcon =
    order.status === EStatusPedido.DisponivelRetirada ||
    (!isDealer &&
      [/* EStatusPedido.Finalizado, */ EStatusPedido.EmProcessamento, EStatusPedido.EmAndamento].includes(order.status))

  const theme = useTheme()
  const { startBrokerOrderProcessing, sendBrokerOrderProjectionFile } = useOrderMutations({
    config: {
      sendBrokerOrderProjectionFile: {
        onSuccess: (res) =>
          setDialog({
            dialog: 'brokerProjectionResponse',
            props: { data: res, orderId: order?.id },
          }),
      },
    },
  })
  const { getBrokerOrderLayout, isLoadingOrderLayout: isGettingBrokerOrderLayout } = useOrdersFiles({
    orderId: order?.id,
    orderNumber: order?.code,
  })

  const { getBrokerOrderXlsx, isGettingBrokerOrderXlsx } = useFilesDownload({
    orderId: order.id,
    orderCode: order.code,
    identifier: 'optionDialog',
  })

  const { sendBrokerOrderSlip, sendBrokerOrderPaymentProof } = useOrderMutations({
    config: {
      sendBrokerOrderSlip: {
        onSuccess: () => goBack({ additionalData: { isRunning: true } }),
      },
    },
  })

  const isLoading =
    isGettingBrokerOrderXlsx ||
    isGettingBrokerOrderLayout ||
    sendBrokerOrderSlip.isLoading ||
    sendBrokerOrderPaymentProof.isLoading

  const handlePlusIconClick = (e) => {
    if ([EStatusPedido.DisponivelRetirada, EStatusPedido.Finalizado].includes(order.status))
      return setMenuAnchor(e.currentTarget)
    else
      setDialog({
        dialog: 'brokerOrderOptions',
        props: {
          order,
          isRunning: EStatusPedido.EmAndamento === order.status,
          isLoading: isLoading,
          onOrderDownload: () => getBrokerOrderXlsx(),
          onOrderExport: () => getBrokerOrderLayout(),
          onAttachSlip: () =>
            setDialog({
              dialog: 'attachBrokerOrderSlip',
              props: {
                order,
                onConfirm: (data) => {
                  const payload = new FormData()
                  payload.append('arquivo', data.get('files'))
                  payload.append('idEmpresa', order.companyId)
                  payload.append('numeroPedido', order.id)

                  sendBrokerOrderSlip.mutate(payload)
                },
              },
            }),
          onPaymentConfirm: () =>
            setDialog({
              dialog: 'attachBrokerOrderPaymentProof',
              props: {
                order,
                onConfirm: (data: FormData) => {
                  data.append('arquivo', data.get('files'))
                  data.append('codigoPedido', order.code)
                  data.append('numeroPedido', order.id)
                  sendBrokerOrderPaymentProof.mutate({ payload: data })
                  setDialog({ dialog: null })
                },
              },
            }),
        },
      })
  }

  const handlePlayIconClick = () => {
    if (order.useBalanceProjection)
      setDialog({
        dialog: 'brokerOrderUploadProjection',
        props: {
          order,
          onConfirm: (data) => {
            sendBrokerOrderProjectionFile.mutate({ orderId: order.id, payload: data })
            setDialog({ dialog: '' })
          },
        },
      })
    else startBrokerOrderProcessing.mutate({ orderId: order.id })
  }

  if (startBrokerOrderProcessing.isLoading || sendBrokerOrderProjectionFile.isLoading)
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress size='20px' />
      </Box>
    )

  return (
    <>
      {shouldShowPlusIcon && (
        <CustomIcon icon={faPlus} color={theme.palette.primary.dark} onClick={handlePlusIconClick} />
      )}
      {isAttendingBroker && !shouldShowPlusIcon && (
        <Tooltip title={isWaitingProcess ? 'Iniciar atendimento' : null}>
          <CustomIcon
            icon={faPlay}
            color={isWaitingProcess ? theme.palette.success.main : theme.palette.grey[500]}
            noHover={!isWaitingProcess}
            onClick={isWaitingProcess ? () => handlePlayIconClick() : null}
          />
        </Tooltip>
      )}
      <OrderMenu order={order} isOpen={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)} anchorEl={menuAnchor} />
    </>
  )
}
