import { useContext, useMemo } from 'react'
import { AutoTableVirtualized } from '@stationkim/front-ui'
import { IOrder } from '../../hooks/useOrderList'
import { getInnerTableColumns } from './utils/innerTableColumns'
import { ListOrderContext } from '../../listOrders'
import { InnerTableContainer } from './styles'

interface InnerTableProps {
  orders: IOrder[]
}

export const InnerTable = ({ orders }: InnerTableProps) => {
  const { setOrderToDetail } = useContext(ListOrderContext)
  const columns = useMemo(() => {
    return getInnerTableColumns({ setOrderToDetail })
  }, [setOrderToDetail])

  return (
    <InnerTableContainer>
      <AutoTableVirtualized
        columns={columns}
        rows={orders}
        visibleRows={orders.length}
        variant='report'
        headerRowHeight={36}
        rowRenderer={({ rowData, cells, columns }) => {
          const orderLink = `/pedidos/recarga#orderId="${rowData.id}"&purchaseId="${rowData.purchaseId}"&isDealerOrder=false`
          return cells.map((cell, index) => {
            const shouldPreventLink = columns[index]?.preventLink
            if (shouldPreventLink) return cell
            return (
              <a
                href={orderLink}
                key={`purchase_${rowData.purchaseId}_order_${rowData.id}_cell_${index}`}
                style={{ all: 'unset', display: 'block', height: '55px' }}
              >
                {cell}
              </a>
            )
          })
        }}
      />
    </InnerTableContainer>
  )
}
