import { AxiosResponse } from 'axios'
import { useAtomValue } from 'jotai'
import { decode } from 'jsonwebtoken'
import api from '@/infra/api'
import { AccountPersistentStore } from '@/main/store'
import { useQuery } from '@tanstack/react-query'
import { IStandardResponse } from '@/main/interfaces'
import { useMemo } from 'react'

type TOperator = {
  active: boolean
  cnpj: string
  code: number
  corporateName: string
  creditProcessingDays: number
  documentNotes: string | null
  email: string
  id: string
  idTacom: number
  municipalRegistration: string | null
  operatorCards: string | null
  processType: number
  registrationStatus: number
  site: string
  stateRegistration: string | null
  status: number
  tradeName: string
}

interface ApiResponse extends IStandardResponse<TOperator[]> {}

export const useOperators = () => {
  const account = useAtomValue(AccountPersistentStore)
  const { resaleId } = decode(account?.auth?.accessToken)
  const { data, isLoading } = useQuery<AxiosResponse<ApiResponse>>({
    queryKey: ['newOrdersOperators', resaleId],
    queryFn: () => api.get(`/revenda-pedidos-bff/operadora?IdRevenda=${resaleId}`),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const organizedData = useMemo(() => {
    const rawData = data?.data
    if (!rawData) return []
    return rawData.valor.map((operator) => ({
      label: operator.tradeName,
      value: operator.id,
    }))
  }, [data])

  return {
    operatorsOptions: organizedData,
    isLoading,
  }
}
