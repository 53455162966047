import 'dayjs/locale/pt-br'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ptBR } from '@mui/x-date-pickers/locales'

export const DatePickerLocaleProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
      adapterLocale='pt-br'
    >
      {children}
    </LocalizationProvider>
  )
}
