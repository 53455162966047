import { DatePickerProps } from '@mui/x-date-pickers'
import { FieldWrapper, FieldWrapperProps, InputBaseProps, InputLabel } from '@stationkim/front-ui'
import { forwardRef } from 'react'
import { Calendar } from '../calendar/calendar'

export interface DatePickerFieldProps<TDate> extends DatePickerProps<TDate>, Pick<InputBaseProps, 'size'> {
  label?: string
}

export const CalendarField = forwardRef(
  <TDate,>(
    {
      id,
      label,
      error = false,
      success = false,
      blocked,
      disabled,
      helperText,
      fullWidth = true,
      size = 'large',
      ...props
    }: DatePickerFieldProps<TDate> & FieldWrapperProps,
    ref: any,
  ) => {
    return (
      <FieldWrapper helperText={helperText} error={error} success={success}>
        <InputLabel htmlFor={id} label={label} />
        <Calendar
          id={id}
          slotProps={{ textField: { inputRef: ref, size, fullWidth, inputProps: { readOnly: true } } }}
          blocked={blocked}
          disabled={blocked || disabled}
          {...props}
        />
      </FieldWrapper>
    )
  },
)
