import { EStatusCompra, EStatusPedido } from '@/main/enums'
import { Theme } from '@mui/material'

export const getColors = (theme: Theme) => ({
  blue: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light + '2D',
  },
  grey: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.grey[100],
  },
  green: {
    color: theme.palette.success.dark,
    backgroundColor: '#02AA5B26',
  },
  red: {
    color: theme.palette.error.dark,
    backgroundColor: theme.palette.error.dark + '1D',
  },
  orange: {
    color: theme.palette.warning.dark,
    backgroundColor: theme.palette.warning.main + '1D',
  },
})

const createStatus = (color: { color: string; backgroundColor: string }, label: string) => ({ sx: color, label })

export const constrictPurchaseStatus = ({ purchaseStatus }: { purchaseStatus: EStatusCompra }) => {
  if (purchaseStatus === undefined) return

  const statusMap = {
    [EStatusCompra.Carrinho]: purchaseStatus,
    [EStatusCompra.Finalizado]: purchaseStatus,
    [EStatusCompra.EmAndamento]: purchaseStatus,
    [EStatusCompra.AguardandoPagamento]: purchaseStatus,
    [EStatusCompra.Efetivando]: EStatusCompra.ProcessandoFormaPagamento,
    [EStatusCompra.ProcessandoFormaPagamento]: EStatusCompra.ProcessandoFormaPagamento,
    [EStatusCompra.Cancelado]: EStatusCompra.Cancelado,
    [EStatusCompra.Cancelando]: EStatusCompra.Cancelado,
  }

  return statusMap[purchaseStatus]
}

export const purchaseStatusChipMap = ({
  purchaseStatus,
  colors,
}: {
  colors: ReturnType<typeof getColors>
  purchaseStatus: EStatusCompra
}) => {
  const statusMap = {
    [EStatusCompra.Carrinho]: createStatus(colors['grey'], 'Rascunho'),
    [EStatusCompra.AguardandoPagamento]: createStatus(colors['orange'], 'Aguar. Pagamento'),
    [EStatusCompra.ProcessandoFormaPagamento]: createStatus(colors['blue'], 'Proc. Forma Pagamento'),
    [EStatusCompra.EmAndamento]: createStatus(colors['blue'], 'Em processamento'),
    [EStatusCompra.Cancelado]: createStatus(colors['red'], 'Cancelado'),
    [EStatusCompra.Finalizado]: createStatus(colors['green'], 'Concluído'),
  }
  return statusMap[purchaseStatus]
}

export const orderStatusChipMap = ({
  orderStatus,
  colors,
}: {
  colors: ReturnType<typeof getColors>
  orderStatus: EStatusPedido
}) => {
  const statusMap = {
    [EStatusPedido.NoCarrinho]: createStatus(colors['grey'], 'Rascunho'),
    [EStatusPedido.Efetivado]: createStatus(colors['blue'], 'Efetivado'),
    [EStatusPedido.Efetivando]: createStatus(colors['blue'], 'Efetivando'),
    [EStatusPedido.AguardandoProcessamento]: createStatus(colors['orange'], 'Aguar. Processamento'),
    [EStatusPedido.EmAndamento]: createStatus(colors['blue'], 'Processamento operadora'),
    [EStatusPedido.EmProcessamento]: createStatus(colors['orange'], 'Em processamento'),
    [EStatusPedido.Cancelado]: createStatus(colors['red'], 'Cancelado'),
    [EStatusPedido.Cancelando]: createStatus(colors['red'], 'Cancelando'),
    [EStatusPedido.Finalizado]: createStatus(colors['green'], 'Concluído'),
  }

  return statusMap[orderStatus]
}
