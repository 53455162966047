import React from 'react'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { Button, Icon, Menu, MenuItem } from '@stationkim/front-ui'

type TMenuItem = {
  title: string
  action: () => void
}

export const OderMenu = ({ items }: { items: TMenuItem[] }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  return (
    <>
      <Button
        sx={{
          minWidth: 'unset',
          width: '32px',
          height: '32px',
          padding: '0',
          borderRadius: '50%',
          color: '#0000008A',
        }}
        onClick={handleClick}
      >
        <Icon icon={faEllipsisVertical} />
      </Button>
      <Menu id='basic-menu' anchorEl={anchorEl} open={open} onClose={handleClose}>
        {items.map((item, index) => (
          <MenuItem onClick={item.action} key={'order_menu_' + index}>
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
