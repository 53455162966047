import { Typography } from '@mui/material'
import { Avatar, Box, Checkbox, Tooltip } from '@stationkim/front-ui'
import { TUser } from '../../userAttributionFilter'
import { Counter } from '../styles'
import { useRef } from 'react'

interface MenuOptionProps {
  user: TUser
  checked: boolean
  onCheck: (user: TUser) => void
  firstItem?: boolean
}

export const MenuOption = ({ user, checked, onCheck, firstItem }: MenuOptionProps) => {
  const handleCheck = () => onCheck(user)

  const isDisabled = firstItem && user.notificationCount === 0
  const textRef = useRef<any>()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        opacity: isDisabled ? 0.6 : 1,
      }}
    >
      <Checkbox
        checked={checked}
        onChange={handleCheck}
        sx={{ transform: 'scale(1.3333)', transformOrigin: 'center' }}
        disabled={isDisabled}
      />
      <Avatar
        src={user.src}
        sx={{ height: '32px', width: '32px', marginLeft: '16px', fontSize: '.875rem' }}
        name={firstItem ? null : user.name}
        disableTooltip
      />
      <Tooltip title={user.name} placement='top'>
        <Typography
          ref={textRef}
          sx={{
            padding: '0 8px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {user.name}
        </Typography>
      </Tooltip>
      <Counter variant={user.notificationCount === 0 ? 'empty' : firstItem ? 'warning' : 'default'}>
        {user.notificationCount}
      </Counter>
    </Box>
  )
}
