import { EStatusCompra, EStatusPedido, ETipoPedido } from '@/main/enums'
import { ORDER_STATUS, PURCHASE_STATUS } from '@/main/utils/enumsMapping'

export const orderStatusOptions = [
  { label: ORDER_STATUS[EStatusPedido.NoCarrinho], value: EStatusPedido.NoCarrinho },
  { label: ORDER_STATUS[EStatusPedido.Efetivado], value: EStatusPedido.Efetivado },
  { label: ORDER_STATUS[EStatusPedido.Efetivando], value: EStatusPedido.Efetivando },
  { label: PURCHASE_STATUS[EStatusCompra.AguardandoPagamento], value: 99 },
  { label: ORDER_STATUS[EStatusPedido.AguardandoProcessamento], value: EStatusPedido.AguardandoProcessamento },
  { label: ORDER_STATUS[EStatusPedido.EmAndamento], value: EStatusPedido.EmAndamento },
  { label: ORDER_STATUS[EStatusPedido.EmProcessamento], value: EStatusPedido.EmProcessamento },
  { label: ORDER_STATUS[EStatusPedido.Cancelado], value: EStatusPedido.Cancelado },
  { label: ORDER_STATUS[EStatusPedido.Cancelando], value: EStatusPedido.Cancelando },
  { label: ORDER_STATUS[EStatusPedido.Finalizado], value: EStatusPedido.Finalizado },
]

export const orderTypesOptions = [
  { label: 'Recarga (Broker)', value: ETipoPedido.RecargaVtBroker },
  //{ label: 'Recarga (Dealer)', value: ETipoPedido.RecargaVtDealer },
  //{ label: '1ª via', value: ETipoPedido.PrimeiraViaVtDealer },
  //{ label: '2ª via', value: ETipoPedido.SegundaViaVtDealer },
]
