import { ORDER_PAYMENT_TYPE, ORDER_STATUS, ORDER_TYPES } from '@/main/utils/constants'
import { formatMoney, getLocaleDateString } from '@/main/utils/functions'
import { OrderActions } from '../components/orderActions/orderActions'
import { Icon, Tooltip } from 'presentation/components/atoms'
import { docIcons } from './icons'

import { Icons } from '../style'

export const getTableConfiguration = ({ updateFilter, hasAction }) => {
  const tableConfiguration = [
    {
      columnDisplayName: 'Código',
      columnValue: ({ row }) => row.code,
      onOrder: ({ order }) => updateFilter({ sort: 'code', desc: !order }),
    },
    {
      columnDisplayName: 'Empresa',
      columnValue: ({ row }) => row.companyName,
    },
    {
      columnDisplayName: 'Data Pedido',
      columnValue: ({ row }) => getLocaleDateString({ date: row.orderDate }),
      onOrder: ({ order }) => updateFilter({ sort: 'orderDate', desc: !order }),
    },
    {
      columnDisplayName: 'Valor',
      columnValue: ({ row }) => `R$ ${formatMoney(row.totalValue)}`,
    },
    {
      columnDisplayName: 'Tipo de Pedido',
      columnValue: ({ row }) => ORDER_TYPES[row.orderType],
    },
    {
      columnDisplayName: 'Tipo Pgto',
      columnValue: ({ row }) => (
        <Icons>
          <Tooltip title={ORDER_PAYMENT_TYPE[row?.paymentType]}>
            <Icon icon={docIcons({ paymentType: ORDER_PAYMENT_TYPE[row?.paymentType] })} />
          </Tooltip>
        </Icons>
      ),
    },
    {
      columnDisplayName: 'Status',
      columnValue: ({ row }) => ORDER_STATUS[row.orderStatus],
    },
    {
      columnDisplayName: 'Ações',
      columnValue: () => '',
      onColumnClick: ({ event }) => {
        event.stopPropagation()
      },
      preventLink: true,
      customRender: ({ row }) => <OrderActions order={row} />,
    },
  ]

  if (!hasAction) {
    tableConfiguration.pop()
  }

  return tableConfiguration
}
