import { styled } from '@mui/material'
import { TextField } from '@stationkim/front-ui'

export const StyledTextField = styled(TextField)((/* { theme, error, disabled } */) => ({
  width: '150px',
  height: '18px !important',
  fontSize: '.875rem',
  /*  borderColor: error ? theme.palette.error.main : 'transparent',
   '&:hover, &:focus-within': !error &&
     !disabled && {
       border: '1px solid ' + theme.palette.grey[400],
     },
   '& input': {
     fontSize: '.875rem',
   }, */
}))
