import dayjs from 'dayjs'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { DatePickerProps as MUIDatePickerProps } from '@mui/x-date-pickers'
import { Icon, IFieldStatus } from '@stationkim/front-ui'
import { StyledDatePicker } from './styles'

export interface DatePickerProps<TDate> extends MUIDatePickerProps<TDate>, IFieldStatus {
  id?: string
}

export function Calendar<TDate>({ id, value, ...props }: DatePickerProps<TDate>) {
  return (
    <StyledDatePicker
      value={dayjs(String(value))}
      format='DD/MM/YYYY'
      slotProps={{
        textField: {
          inputProps: { id, disabled: true },
          error: false,
        },
      }}
      slots={{
        openPickerIcon: () => <Icon icon={faCalendar} />,
      }}
      {...(props as any)}
    />
  )
}
