import React from 'react'
import { FileTitle, DropContainer, SubText, Document } from './style'
import { Button, Icon } from '@/presentation/components'
import fileSize from 'filesize'

const DownloadLine = ({ children, donwloadUrl, format, filesize, uploadingFile }) => {
  return (
    <DropContainer fileName={uploadingFile?.name}>
      <div style={{ display: 'flex' }}>
        <Document>
          <Icon name='document' />
        </Document>
        <div>
          <FileTitle>{children}</FileTitle>
          <SubText>
            {format} {fileSize(filesize || 0)}
          </SubText>
        </div>
      </div>
      <a href={donwloadUrl}>
        <Button variant='action' icon='download' width='200px'>
          Baixar Arquivo
        </Button>
      </a>
    </DropContainer>
  )
}

export default DownloadLine
