import { styled } from '@mui/material'
import { Box, Typography } from '@stationkim/front-ui'

export const EmployeeRechargeSummaryContainer = styled(Box)(({ theme }) => ({
  border: '1px solid' + theme.palette.grey[300],
  borderRadius: '8px',
  padding: '8px',
  margin: '16px 0',
}))

export const EmployeeNameAndRegistration = styled(Box)(() => ({
  display: 'flex',
  gap: '16px',
  '& p': {
    fontSize: '1.125rem',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& p:last-of-type': {
    textDecoration: 'underline',
  },
}))

export const RechargeValue = styled(Typography)(({ theme }) => ({
  fontSize: '.75rem',
  color: theme.palette.grey[700],
  '& span': {
    fontSize: '1.125rem',
    color: theme.palette.common.black,
    fontWeight: 600,
  },
}))

export const CardNumber = styled(Typography)(({ theme }) => ({
  fontSize: '.875rem',
  color: theme.palette.grey[700],
}))

export const Label = styled('label')(({ theme }) => ({
  fontSize: '.875rem',
  display: 'inline-block',
  marginBottom: '8px',
  fontWeight: 600,
  '& span': {
    fontSize: '1.125rem',
    color: theme.palette.error.main,
    fontWeight: 600,
    marginLeft: '8px',
  },
}))
