import { useTheme } from '@mui/material'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Tooltip } from '@stationkim/front-ui'
import { StyledTextField } from './styles'

interface CardFieldProps {
  form: UseFormReturn<{ cardNumber: string }>
  disabled: boolean
  onBlur: () => void
}

export const CardField = ({ form, disabled, onBlur }: CardFieldProps) => {
  const theme = useTheme()
  return (
    <form onSubmit={onBlur}>
      <Controller
        name='cardNumber'
        control={form.control}
        rules={{
          validate: {
            size: (value) => {
              if (value?.length < 8 || !value) return 'O Cartão deve ter ao menos 8 dígitos'
              if (value?.length > 16 || !value) return 'O Cartão deve ter no máximo 16 dígitos'
              return true
            },
          },
        }}
        render={({ field: { value, onChange, ref }, fieldState: { error, isDirty } }) => {
          return (
            <Tooltip
              title={isDirty && error?.message}
              slotProps={{
                tooltip: {
                  sx: {
                    color: theme.palette.error.light,
                    background: theme.palette.common.white,
                    boxShadow: '4px 4px 30px 0px rgba(19, 19, 19, 0.10)',
                  },
                },
                arrow: { sx: { color: theme.palette.common.white } },
              }}
            >
              <StyledTextField
                value={value}
                onChange={(e) => {
                  const newValue = e.target.value
                  onChange(newValue.replaceAll(/[^0-9]/gi, ''))
                }}
                inputRef={ref}
                size='small'
                error={isDirty && Boolean(error)}
                onBlur={onBlur}
                disabled={disabled}
                blocked={disabled}
              />
            </Tooltip>
          )
        }}
      />
    </form>
  )
}
