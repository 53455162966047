import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Theme } from '@mui/material'
import { Icon } from '@stationkim/front-ui'

export const ExpandIcon = ({ expandable, expanded, onExpand }) => {
  if (!expandable) return null
  return (
    <Icon
      icon={faChevronRight}
      sx={(theme: Theme) => ({
        height: '10px',
        marginRight: '8px',
        transform: `rotate(${expanded ? 90 : 0}deg)`,
        cursor: 'pointer',
        color: theme.palette.primary.light,
        transition: 'all .15s ease-in-out',
        minWidth: '12px',
        minHeight: '12px',
      })}
      onClick={() => onExpand(!expanded)}
    />
  )
}
