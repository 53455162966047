import { PrimitiveAtom, useAtom } from 'jotai'
interface IUsePagination {
  paginationAtom: PrimitiveAtom<object>
  initialValue?: object
  outputMap?: {
    page?: string
    pageSize?: string
    totalPages?: string
    totalItens?: string
  }
}
export const usePagination = ({
  paginationAtom,
  initialValue = { page: 1, pageSize: 10 },
  outputMap,
}: IUsePagination) => {
  const [pagination, setPagination] = useAtom(paginationAtom)
  const goToPage = (page: number) =>
    setPagination(() => {
      if (pagination) return { ...pagination, page }
      return { ...initialValue, page }
    })

  const setPageSize = (pageSize: number) =>
    setPagination(() => {
      if (pagination) return { ...pagination, page: 1, pageSize }
      return { ...initialValue, pageSize }
    })

  const getValue = () => {
    if (!outputMap) return pagination || initialValue
    const ref = pagination || initialValue
    return Object.keys(ref || []).reduce((finalObj, key) => {
      const obj = { ...finalObj }
      if (outputMap[key]) {
        obj[outputMap[key]] = ref[key]
        return obj
      }
      obj[outputMap[key]] = ref[key]
      return obj
    }, {})
  }
  return {
    pagination: getValue() || initialValue,
    goToPage,
    setPageSize,
  }
}
