import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
} from '@/presentation/components/atoms'
import { useOrderMutations } from '../../../hooks/useOrderMutations'
import { Form } from './styles'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './utils/validations'

export const FinishPickUpOrderDialog = ({ onClose, orderToFinish, open }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const { finalizeOrder } = useOrderMutations({
    config: {
      finalizeOrder: {
        onSuccess: () => {
          onClose()
        },
      },
    },
  })

  const submit = (data) => {
    finalizeOrder.mutate({ orderId: orderToFinish, deliveryCode: data?.code })
  }

  const isLoading = finalizeOrder.isLoading

  return (
    <Dialog open={open} onClose={isLoading ? null : () => onClose()}>
      <DialogTitle id='alert-dialog-title' sx={{ marginBottom: '32px' }}>
        Concluir pedido
      </DialogTitle>
      <DialogContent
        sx={{ width: '536px', padding: '0', display: 'flex', justifyContent: isLoading ? 'center' : 'start' }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          'Para concluir o pedido, informe o ID da entrega. Após concluída, a ação não poderá ser desfeita.'
        )}
      </DialogContent>

      <Form onSubmit={handleSubmit(submit)}>
        <div>
          <label>Informe o ID da entrega</label>
          <input placeholder='000000' name='code' {...register('code', { required: 'Campo obrigatório!' })} />
          <span>{(errors?.code?.message || '') as string}</span>
        </div>
        <DialogActions sx={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: '32px' }}>
          <Button disabled={isLoading} variant='outlined' onClick={onClose}>
            Cancelar
          </Button>
          <Button disabled={isLoading} variant='contained' type='submit' autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Form>
      <Form onSubmit={handleSubmit(submit)}>
        <div>
          <label>Informe o ID da entrega</label>
          <input placeholder='000000' name='code' {...register('code', { required: 'Campo obrigatório!' })} />
          <span>{(errors?.code?.message || '') as string}</span>
        </div>
        <DialogActions sx={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: '32px' }}>
          <Button disabled={isLoading} variant='outlined' onClick={onClose}>
            Cancelar
          </Button>
          <Button disabled={isLoading} variant='contained' type='submit' autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}
