import { useQuery } from '@tanstack/react-query'
import api from '@/infra/api'
import { downloadFile } from '@/main/utils/functions'
import { useEffect } from 'react'

export const useOrdersFiles = ({ orderId, orderNumber }: { orderId: string; orderNumber: string | number }) => {
  const {
    data,
    refetch: getBrokerOrderLayout,
    isFetching: isLoadingOrderLayout,
  } = useQuery({
    queryKey: ['brokerOrderLayout', orderId],
    queryFn: () => api.get(`/revenda-pedidos-bff/pedido/gerararquivo/${orderId}`),
    enabled: false,
  })

  useEffect(() => {
    const res = data?.data?.valor?.base64Result
    if (res && !isLoadingOrderLayout)
      downloadFile({
        download: `Layout do Pedido ${orderNumber}.txt`,
        href: `data:text/plain;base64,${res}`,
      })
  }, [data, orderNumber, isLoadingOrderLayout])

  return {
    getBrokerOrderLayout,
    isLoadingOrderLayout,
  }
}
