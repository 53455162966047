import { Panel } from '@/presentation/components/molecules'
import { PageHeader } from '@/presentation/components/molecules'
import { Tab, TabPanel, Tabs } from '@/presentation/components/atoms'
import { DigitalAccountExtract } from './digitalAccountExtract/digitalAccountExtract'
import { PaymentsConciliation } from './paymentsConciliation/import/paymentsConciliation'
import { Dashboard } from './dashboard/dashboard'
import { atomWithHash } from 'jotai-location'
import { useAtom } from 'jotai'
import { useFeatures } from '@/main/hooks'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'

const financialManagementTabAtom = atomWithHash('financialManagementTab', 0)
function FinancialManagement() {
  const { resources } = useAccessProfile({ resource: 'financeiro' })

  const [selectedTab, setSelectedTab] = useAtom(financialManagementTabAtom)
  const { features } = useFeatures()
  const handleTabChange = (_event: React.SyntheticEvent, targetTab: number) => {
    setSelectedTab(targetTab)
  }

  const tabsConfig = [
    resources({ item: 'financeiro_dashboard' })
      ? {
          tabName: 'Relatórios financeiros',
          component: <Dashboard />,
          isAvailable: features.dashboard,
        }
      : null,
    resources({ item: 'financeiro_conciliacao' })
      ? {
          tabName: 'Conciliação de pagamentos',
          component: <PaymentsConciliation />,
          isAvailable: features.paymentsConciliation,
        }
      : null,
    resources({ item: 'financeiro_extrato' })
      ? {
          tabName: 'Extrato conta digital',
          component: <DigitalAccountExtract />,
          isAvailable: features.digitalAccountExtract,
        }
      : null,
  ]

  const tabsToRender = tabsConfig.filter((tab) => tab?.isAvailable)

  return (
    <Panel sx={{ padding: { xs: '16px', md: '32px' }, minWidth: '385px' }}>
      <PageHeader pageTitle='Financeiro' padding='0' useTitleAsBreadcrumb={false} />
      <Tabs value={selectedTab} onChange={handleTabChange}>
        {tabsToRender.map((tab, index) => (
          <Tab key={'tab' + index} label={tab.tabName} sx={{ textTransform: 'capitalize' }} />
        ))}
      </Tabs>

      {tabsToRender.map((tab, index) => (
        <TabPanel key={'tabPanel' + index} index={index} value={selectedTab}>
          {tab.component}
        </TabPanel>
      ))}
    </Panel>
  )
}

export default FinancialManagement
