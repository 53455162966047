import { deepmerge } from '@mui/utils'
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material'
import { GlobalThemePersistentStore } from '@/main/store/globalTheme'
import { useAtomWithStorageObserver } from '@/main/hooks'
import { defaultTheme } from '@stationkim/front-ui'

export const ThemeProvider = ({ children }) => {
  const [globalTheme] = useAtomWithStorageObserver({
    atomWithStorage: GlobalThemePersistentStore,
    storageName: 'globalTheme',
  })
  const theme = createTheme(deepmerge(defaultTheme, { palette: { brand: globalTheme?.palette?.brand } }))

  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
}
