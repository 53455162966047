import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from '@/store'
import { DatePickerLocaleProvider, QueryProvider, RoutesProvider, ThemeProvider } from '@/config/providers'
import { useClarity } from './main/hooks/useClarity'

function App() {
  useClarity()
  return (
    <ThemeProvider>
      <QueryProvider>
        <DatePickerLocaleProvider>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <RoutesProvider />
            </PersistGate>
          </Provider>
        </DatePickerLocaleProvider>
      </QueryProvider>
    </ThemeProvider>
  )
}
export default App
