import { SxProps, useTheme } from '@mui/material'
import _ from 'lodash'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { CircularProgress, Icon, IconButton, Tooltip } from '@stationkim/front-ui'
import { Box, Grid, Typography } from 'presentation/components/atoms'
import { ItemHeader, ItemLabel, ItemValuesContainer } from './styles'
import { formatMoney } from 'main/utils/functions'
import { BENEFIT_TYPE, BENEFIT_TYPE_KEY } from 'main/utils/constants'
import { IOrderItem } from '@/presentation/pages/orderTracking/utils/interfaces/iOrderItem'
import { useForm } from 'react-hook-form'
import { CardField } from './components/cardField/cardField'
import { useAddCardToItem } from '@/presentation/pages/orderTracking/hooks/useAddCardToItem'
import { useContext, useRef } from 'react'
import { OrderTrackingContext } from '@/presentation/pages/orderTracking/orderTracking'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from '@/main/store'

const GridValue = ({
  label,
  value,
  isMonetary,
  sx,
}: {
  blocked?: boolean
  label: string
  value: string | number | React.ReactElement
  isMonetary?: boolean
  sx?: SxProps
}) => {
  return (
    <Grid item xs='auto' key={_.uniqueId()} sx={sx}>
      <ItemValuesContainer>
        <ItemLabel variant='text2' sx={{ whiteSpace: 'nowrap' }}>
          {label}
        </ItemLabel>
        {typeof value === 'string' || typeof value === 'number' ? (
          <Typography variant='label2' sx={{ fontWeight: 400, whiteSpace: 'nowrap', marginBottom: '4px' }} id='value'>
            {isMonetary ? `R$ ${formatMoney(Number(value))}` : value}
          </Typography>
        ) : (
          value
        )}
      </ItemValuesContainer>
    </Grid>
  )
}

export const OrderItem = ({
  orderItem,
  onRemoveEmployee,
  removable,
}: {
  orderItem: IOrderItem
  removable: boolean
  onRemoveEmployee: (param: { orderItem: IOrderItem }) => void
}) => {
  const theme = useTheme()
  const notify = useNotifications()
  const { orderId } = useContext(OrderTrackingContext)
  const { updateCardInfo } = useAddCardToItem()
  const form = useForm<{ cardNumber: string }>({
    mode: 'onChange',
    defaultValues: {
      cardNumber: orderItem.cardNumber,
    },
    shouldFocusError: false,
  })
  const initialValue = useRef(orderItem.cardNumber)

  const onSubmit = (data) => {
    if (initialValue.current === data?.cardNumber) return
    updateCardInfo.mutate(
      {
        cardNumber: data?.cardNumber || '',
        employeeGuid: orderItem.employeeGuid,
        orderId: orderId,
      },
      {
        onSuccess: () => {
          notify.push({ content: 'Número de cartão atualizado com sucesso!', type: EMessageType.Success })
          initialValue.current = data?.cardNumber
        },
      },
    )
  }

  return (
    <Box sx={{ opacity: orderItem.removed ? 0.7 : 1 }}>
      <ItemHeader>
        <Box sx={{ textDecoration: orderItem.removed ? 'line-through' : 'initial', display: 'flex', gap: '16px' }}>
          {Boolean(orderItem.employeeRegistration) && <Typography>{orderItem.employeeRegistration}</Typography>}
          <Typography>{orderItem.employeeName}</Typography>
        </Box>
        {orderItem.removed ? (
          <Typography sx={(theme) => ({ color: theme.palette.error.main, fontWeight: 600, fontSize: '.875rem' })}>
            Colaborador Excluído
          </Typography>
        ) : (
          removable && (
            <Tooltip title='Excluir do pedido' placement='top'>
              <IconButton color='error' onClick={() => onRemoveEmployee({ orderItem })}>
                <Icon icon={faTrash} style={{ color: theme.palette.error.light }} />
              </IconButton>
            </Tooltip>
          )
        )}
      </ItemHeader>
      <Grid container spacing='8px' justifyContent='space-between' blocked={orderItem.removed}>
        <GridValue label='Produto' value={BENEFIT_TYPE[orderItem.benefitType as BENEFIT_TYPE_KEY]} />

        <GridValue
          label='N° Cartão'
          sx={{
            '& > div > span:first-child': {
              marginLeft: '8px',
            },
          }}
          value={
            updateCardInfo.isLoading ? (
              <CircularProgress size='16px' sx={{ margin: 'auto' }} />
            ) : (
              <CardField disabled={!removable || orderItem.removed} onBlur={form.handleSubmit(onSubmit)} form={form} />
            )
          }
        />
        <GridValue label='Mat. Operadora' value={orderItem.operatorRegistration} />
        <GridValue label='Dias' value={orderItem.days} />
        <GridValue label='Valor dia' value={orderItem.dayValue} isMonetary />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'end',
          }}
        >
          <GridValue label='Total' value={orderItem.total} isMonetary />
        </Box>
      </Grid>
    </Box>
  )
}
