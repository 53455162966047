import { atomWithStorage } from 'jotai/utils'

const defaultAccount: AccountModel = {
  resale: {
    digitalAccountName: 'Carteira Digital',
    displayName: '',
    email: '',
    images: {
      footer: '',
      header: '',
      icon: '',
      login: '',
    },
    instagram: '',
    linkedin: '',
    name: '',
    phone: '',
    site: '',
    theme: '',
    twitter: '',
    allowUserMenu: false,
  },
}
export interface AccountModel {
  auth?: {
    accessToken: string
    expiresIn: string
    refreshToken: string
  }
  resale?: {
    digitalAccountName: string
    displayName: string
    email: string | null
    images: {
      footer: string | null
      header: string | null
      icon: string | null
      login: string | null
    }
    instagram: string | null
    linkedin: string | null
    name: string | null
    phone: string | null
    site: string | null
    theme: string | null
    twitter: string | null
    allowUserMenu: boolean
  }
  user?: {
    name: string
    urlPhoto: string | null
    level: string
  }
  company?: {
    id: string
    name: string
  }
}

export const AccountPersistentStore = atomWithStorage<AccountModel>(
  'account',
  JSON.parse(localStorage.getItem('account')) ?? defaultAccount,
)
