import React, { useCallback, useContext, useMemo, useState } from 'react'
import _ from 'lodash'
import { OrderTrackingContext } from '../../orderTracking'
import { Box, Card, Divider } from 'presentation/components/atoms'
import { Search, Summary, TableFooterPagination } from 'presentation/components/molecules'
import { ReactComponent as CardIcon } from 'presentation/assets/icons/card.svg'
import { ReactComponent as RechargeIcon } from 'presentation/assets/icons/recharge.svg'
import { OrderItem } from './components/orderItem/orderItem'
import { OrderItemsSkeleton } from './components/orderItemsSkeleton/orderItemsSkeleton'
import { NoItems } from './components/noItems/noItems'
import { OrderTitle } from './components/orderTitle/orderTitle'
import { ORDER_STATUS } from 'main/utils/constants'
import { useRemoveFromOrder } from '../../hooks/useRemoveFromOrder'
import { IOrderItem } from '../../utils/interfaces/iOrderItem'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from '@/main/store'
import { useQueryClient } from '@tanstack/react-query'
import { RemoveItemDialog } from './components/removeItemDialog/removeItemDialog'

export const OrderItems = () => {
  const { orderDate, isRecharge, orderStatus, items, orderId } = useContext(OrderTrackingContext)
  const { orderItems, isLoading, filter, goToPage, setPageSize, updateFilter } = items || {}
  const notifications = useNotifications()
  const queryClient = useQueryClient()
  const [itemToRemove, setItemToRemove] = useState<IOrderItem | null>(null)
  const { removeItem } = useRemoveFromOrder()
  const removable = orderStatus > 3 && orderStatus < 10 // should change when 100% isolated from old api

  const handleRemoval = useCallback(
    (orderItem) => {
      let unremovedCount = 0
      const canBeRemoved = orderItems.data.some((item) => {
        if (!item.removed) {
          unremovedCount++
          if (unremovedCount > 1) return true
          return false
        }
      })
      if (canBeRemoved) {
        setItemToRemove(orderItem)
      } else {
        notifications.push({
          content: 'Este é o último item do pedido para excluí-lo, será necessário cancelar o pedido.',
          type: EMessageType.Warning,
        })
      }
    },
    [orderItems],
  )

  const totalItems = orderItems?.totalItems || 0
  const itemsToRender = useMemo(() => {
    if (!orderItems || !orderItems.data || orderItems.data.length === 0)
      return <NoItems>{filter?.filterValue !== '' ? 'Não há resultado para a pesquisa' : 'Sem registro'}</NoItems>
    return orderItems.data.map((orderItem: IOrderItem, index: number) => {
      const shouldAddDivider = index !== 0
      return (
        <React.Fragment key={_.uniqueId()}>
          {shouldAddDivider && <Divider sx={{ margin: '12px 0px 24px' }} />}
          <OrderItem
            orderItem={orderItem}
            removable={removable}
            onRemoveEmployee={({ orderItem }) => handleRemoval(orderItem)}
          />
        </React.Fragment>
      )
    })
  }, [orderItems, filter?.filterValue, removable, handleRemoval])

  const generateItemsString = () => {
    if (isRecharge) return totalItems > 1 ? 'Colaboradores' : 'Colaborador'
    else return totalItems > 1 ? 'Itens' : 'Item'
  }

  return (
    <Card sx={{ padding: '16px', marginTop: '32px' }}>
      <Summary
        title={<OrderTitle isCanceled={[ORDER_STATUS.CANCELED, ORDER_STATUS.CANCELING].includes(orderStatus)} />}
        subtitle={`${orderDate} ⚬ ${totalItems} ${generateItemsString()} `}
        icon={isRecharge ? <RechargeIcon /> : <CardIcon />}
        noMargin
      >
        <Box sx={{ overflow: 'hidden' }}>
          <Divider sx={{ marginBottom: '16px' }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
            <Search
              onSearch={(value: string) => {
                goToPage(1)
                updateFilter({
                  filterValue: String(value).replaceAll(/[./-]/g, ''),
                  filterProps: 'employeeName,employeeRegistration,operatorRegistration,cardNumber',
                })
              }}
              placeholder='Pesquisar na tabela abaixo...'
              sx={{ maxWidth: '342px', filter: 'grayscale(100%)' }}
            />
          </Box>

          {isLoading ? <OrderItemsSkeleton /> : itemsToRender}
          <TableFooterPagination
            onRowsPerPageChange={(e) => setPageSize(e.target.value)}
            onPageChange={(e, value) => goToPage(value)}
            page={orderItems.page}
            rowsPerPage={orderItems.pageSize}
            totalItems={orderItems.totalItems}
            totalPages={orderItems.totalPages}
            displayText='N° de linhas'
            counterVariant='interval'
            hideSinglePage={true}
            rowsPerPageOptions={[10, 50, 200, 500]}
          />
        </Box>
      </Summary>
      {Boolean(itemToRemove) && (
        <RemoveItemDialog
          open={Boolean(itemToRemove)}
          orderItem={itemToRemove}
          isLoading={removeItem.isLoading}
          onClose={() => setItemToRemove(null)}
          onConfirm={({ deleteReason, employeeId }) => {
            removeItem.mutate(
              { deleteReason, employeeId, orderId },
              {
                onSuccess: () => {
                  setItemToRemove(null)
                  queryClient.invalidateQueries({ queryKey: ['newOrderDetail'] })
                  queryClient.invalidateQueries({ queryKey: ['newOrderDetailItems'] })
                  notifications.push({ content: 'Item excluído com sucesso!', type: EMessageType.Success })
                },
              },
            )
          }}
        />
      )}
    </Card>
  )
}
