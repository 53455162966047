import { useEffect, useRef, useState } from 'react'

import { PlatformPersonalization } from '@/presentation/components/organisms/platformPersonalization'
import { CompanyContactsEditionDialog } from '@/presentation/components/organisms/companyContactsEditionDialog'
import { BrandingEditionDialog } from '@/presentation/components/organisms/brandingEditionDialog'
import { AccountInfoEditionDialog } from '@/presentation/components/organisms/accountInfoEditionDialog'
import { ColorSelectionDialog } from '@/presentation/components/molecules/colorSelectionDialog'

import { useDispatch } from 'react-redux'
import { updateUserStore } from 'store/modules/user/actions'
import { postEditBranding, putEditAccountInfo } from 'services/themeServices'
import { pushNotification } from 'store/modules/notification/actions'
import successHandler from '@/main/utils/successHandler'

import { getCustomizationSetting } from 'services/themeServices'
import { GlobalThemePersistentStore } from '@/main/store/globalTheme'
import { useAtomWithStorageObserver } from '@/main/hooks'
import { putEditTheme } from 'services/themeServices'

import useService from '@/main/hooks/useService'
import { Box } from '@/presentation/components/atoms/box'
import { Spinner } from '@/presentation/components/atoms'
import { Container } from './styles'
import { defaultTheme } from '@stationkim/front-ui'

const MODAL = {
  closed: 0,
  account: 1,
  colors: 2,
  branding: 3,
  contacts: 4,
}

export const Customization = () => {
  const [openModal, setOpenModal] = useState(0)

  const dispatch = useDispatch()

  const dataForUpdate = useRef({})
  const [editAccountState, editAccountRequest] = useService(putEditAccountInfo, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler('Informações alteradas com sucesso!')))
      dispatch(
        updateUserStore({
          resale: { ...dataForUpdate.current },
        }),
      )
      fetchConfigurationRequest()
    },
  })

  const [updateBrandingState, updateBrandingRequest] = useService(postEditBranding, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler('Branding alterado com sucesso!')))
      location.reload()
    },
  })

  const handleColorChange = (colors) => {
    const newTheme = { ...theme }
    newTheme.palette.brand = {
      primary: colors.primary,
      secondary: colors.secondary,
    }
    setTheme(newTheme)
  }

  useEffect(() => {
    fetchConfigurationRequest()
  }, [])

  const [theme, setTheme] = useAtomWithStorageObserver({
    atomWithStorage: GlobalThemePersistentStore,
    storageName: 'globalTheme',
  })

  const [lastSelectedColors, setLastSelectedColors] = useState({
    primary: theme.palette?.brand?.primary,
    secondary: theme.palette?.brand?.secondary,
  })

  const [fetchConfigurationState, fetchConfigurationRequest] = useService(getCustomizationSetting, {
    onCompleted: (res) => {
      const customTheme = JSON.parse(res.data.theme)?.palette?.brand
      if (customTheme) defaultTheme.palette.brand = customTheme
      setTheme(defaultTheme)
    },
  })

  const resaleData = fetchConfigurationState.response?.data
  const resaleContacts = resaleData?.contact

  const [themeEditionState, themeEditionRequest] = useService(putEditTheme, {
    onCompleted: () => {
      setLastSelectedColors({
        primary: theme.palette?.brand?.primary,
        secondary: theme.palette?.brand?.secondary,
      })
      setTheme(theme)
      dispatch(pushNotification(successHandler('Cores alteradas com sucesso!')))
    },
    onError: () => {
      handleColorChange(lastSelectedColors)
    },
  })

  const isLoading =
    themeEditionState.loading ||
    fetchConfigurationState.loading ||
    editAccountState.loading ||
    updateBrandingState.loading

  return isLoading ? (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Spinner />
    </Box>
  ) : (
    <Container>
      <PlatformPersonalization
        companyData={{
          contacts: resaleData?.contact || {},
          images: {
            header: resaleData?.images?.header?.url,
            footer: resaleData?.images?.footer?.url,
            login: resaleData?.images?.login?.url,
          },
          site: resaleData?.url,
        }}
        configuration={{
          dialogsSetters: {
            account: () => setOpenModal(MODAL.account),
            colors: () => setOpenModal(MODAL.colors),
            branding: () => setOpenModal(MODAL.branding),
            contacts: () => setOpenModal(MODAL.contacts),
          },
          lastSelectedColors,
        }}
      />
      {openModal === MODAL.account ? (
        <AccountInfoEditionDialog
          open={openModal === MODAL.account}
          onClose={() => setOpenModal(MODAL.closed)}
          onSave={(data) => {
            editAccountRequest(data)
            dataForUpdate.current = data
            setOpenModal(MODAL.closed)
          }}
          initialValue={{
            digitalAccountName: resaleContacts?.digitalAccountName,
            displayName: resaleContacts?.displayName,
          }}
        />
      ) : null}
      {openModal === MODAL.colors ? (
        <ColorSelectionDialog
          open={openModal === MODAL.colors}
          onChange={(color) => handleColorChange(color)}
          onConfirm={() => {
            delete theme.components
            themeEditionRequest({ palette: JSON.stringify(theme) })
            setOpenModal(MODAL.closed)
          }}
          onClose={() => {
            handleColorChange(lastSelectedColors)
            setOpenModal(MODAL.closed)
          }}
        />
      ) : null}
      {openModal === MODAL.branding ? (
        <BrandingEditionDialog
          open={openModal === MODAL.branding}
          onConfirm={(data) => {
            updateBrandingRequest(data)
            setOpenModal(MODAL.closed)
          }}
          onClose={() => {
            setOpenModal(MODAL.closed)
          }}
          initialValues={{
            header: new File([''], resaleData?.images?.header?.key),
            footer: new File([''], resaleData?.images?.footer?.key),
            login: new File([''], resaleData?.images?.login?.key),
          }}
        />
      ) : null}
      {openModal === MODAL.contacts ? (
        <CompanyContactsEditionDialog
          open={openModal === MODAL.contacts}
          onClose={() => setOpenModal(MODAL.closed)}
          onSave={(data) => {
            editAccountRequest(data)
            dataForUpdate.current = data
            setOpenModal(MODAL.closed)
          }}
          initialValue={{
            email: resaleContacts?.email,
            twitter: resaleContacts?.twitter,
            instagram: resaleContacts?.instagram,
            linkedin: resaleContacts?.linkedin,
            phone: resaleContacts?.phone,
          }}
        />
      ) : null}
    </Container>
  )
}
