import { Typography } from '@mui/material'
import { OrderStatusComponentsProps } from '../orderStatusComponentsProps'
import { ORDER_TYPES } from 'main/utils/constants'

export const Finished = ({ orderType = 0, code }: OrderStatusComponentsProps) => {
  let finalText = ''
  if ([ORDER_TYPES.FIRST_COPY, ORDER_TYPES.SECOND_COPY].includes(orderType))
    finalText = ' e os produtos já foram entregues'
  else if ([ORDER_TYPES.RECHARGE, ORDER_TYPES.BROKER].includes(orderType)) finalText = ' e a recarga já foi efetivada'
  return (
    <>
      <Typography variant='h3' sx={{ fontWeight: 500 }}>
        Seu pedido foi concluído{finalText}.
      </Typography>
      {orderType === 1 || orderType === 2 ? (
        <p style={{ marginTop: '12px' }}>
          ID da entrega: <strong>{code}</strong>
        </p>
      ) : null}
    </>
  )
}
