import { EStatusCompra } from '@/main/enums'
import { ORDER_STATUS, ORDER_TYPES, PURCHASE_STATUS } from '@/main/utils/enumsMapping'
import { usePersistentFilterWithHash, ValueChip } from '@stationkim/front-ui'

type TRenderFunction = (params: {
  filter: ReturnType<typeof usePersistentFilterWithHash>['filter']
  updateFilter: ReturnType<typeof usePersistentFilterWithHash>['updateFilter']
  companiesOptions: { label: string; value: string }[]
  operatorsOptions: { label: string; value: string }[]
}) => React.ReactNode

const simpleFilterRemove = ({ filter, filterName }: { filter: Record<string, any>; filterName: string }) => {
  const newFilter = { ...filter }
  delete newFilter[filterName]
  return newFilter
}

export const statusChipRender: Record<string, TRenderFunction> = {
  statusPedido: ({ filter, updateFilter }) => {
    const filterValue = filter['statusPedido']
    return (
      <ValueChip
        label={'Status'}
        value={{
          label:
            filterValue === 99
              ? PURCHASE_STATUS[EStatusCompra.AguardandoPagamento]
              : ORDER_STATUS[filter['statusPedido']],
          value: filter['statusPedido'],
        }}
        onRemove={() => {
          updateFilter({
            override: true,
            value: simpleFilterRemove({
              filter: filter,
              filterName: 'statusPedido',
            }),
          })
        }}
      />
    )
  },
  tipoPedido: ({ filter, updateFilter }) => {
    return (
      <ValueChip
        label={'Tipo de pedido'}
        value={{
          label: ORDER_TYPES[filter['tipoPedido']],
          value: filter['tipoPedido'],
        }}
        onRemove={() => {
          updateFilter({
            override: true,
            value: simpleFilterRemove({
              filter: filter,
              filterName: 'tipoPedido',
            }),
          })
        }}
      />
    )
  },
  guidsOperadora: ({ filter, updateFilter, operatorsOptions }) => {
    const filterValue = filter['guidsOperadora']
    return (
      <ValueChip
        label={filterValue?.length > 1 ? 'Operadoras' : 'Operadora'}
        value={filterValue.map((selectedOperator) => {
          const operator = operatorsOptions.find((option) => option.value === selectedOperator)
          return {
            valuer: operator ? operator.value : '?',
            label: operator ? operator.label : '?',
          }
        })}
        onRemove={({ index }) => {
          const newFilter = { ...filter }
          const newOptions = [...newFilter['guidsOperadora']]
          if (newOptions.length > 1) {
            newOptions.splice(index, 1)
            newFilter['guidsOperadora'] = newOptions
          } else delete newFilter['guidsOperadora']
          updateFilter({
            override: true,
            value: newFilter,
          })
        }}
      />
    )
  },
  guidEmpresa: ({ filter, updateFilter, companiesOptions }) => {
    const filterValue = filter['guidEmpresa']
    const company = companiesOptions.find((option) => option.value === filterValue)
    return (
      <ValueChip
        label={'Empresa'}
        value={{
          label: company ? company.label : '?',
          value: filterValue,
        }}
        onRemove={() => {
          updateFilter({
            override: true,
            value: simpleFilterRemove({
              filter: filter,
              filterName: 'guidEmpresa',
            }),
          })
        }}
      />
    )
  },
  dataCredito: ({ filter, updateFilter }) => {
    return (
      <ValueChip
        label={'Data do crédito'}
        value={{
          label: new Date(filter['dataCredito']).toLocaleDateString(),
          value: filter['dataCredito'],
        }}
        onRemove={() => {
          updateFilter({
            override: true,
            value: simpleFilterRemove({
              filter: filter,
              filterName: 'dataCredito',
            }),
          })
        }}
      />
    )
  },
  dataMinima: ({ filter, updateFilter }) => {
    return (
      <ValueChip
        label={'De'}
        value={{
          label: new Date(filter['dataMinima']).toLocaleDateString(),
          value: filter['dataMinima'],
        }}
        onRemove={() => {
          updateFilter({
            override: true,
            value: simpleFilterRemove({
              filter: filter,
              filterName: 'dataMinima',
            }),
          })
        }}
      />
    )
  },
  dataMaxima: ({ filter, updateFilter }) => {
    return (
      <ValueChip
        label={'Até'}
        value={{
          label: new Date(filter['dataMaxima']).toLocaleDateString(),
          value: filter['dataMaxima'],
        }}
        onRemove={() => {
          updateFilter({
            override: true,
            value: simpleFilterRemove({
              filter: filter,
              filterName: 'dataMaxima',
            }),
          })
        }}
      />
    )
  },
}
