import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { styled } from '@mui/material'
import { DatePickerProps } from '@mui/lab'

export const StyledDatePicker = styled(DatePicker)<DatePickerProps<any>>(({ theme, blocked }) => ({
  borderColor: theme.palette.grey[200] + ' !important',
  '& .MuiInputBase-root': {
    height: 48,
    borderRadius: 8,
    backgroundColor: theme.palette.grey[200],
    overflow: 'hidden',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: blocked ? 'none' : '',
  },
  '& .MuiInputBase-input': {
    padding: '12px 8px 12px 22px',
    fontSize: '.875rem',
    textFillColor: 'unset !important',
    color: theme.palette.common.black,
  },
  '& .MuiInputBase-root ': {
    flexDirection: 'row-reverse',
  },
  '& fieldset': {
    border: 'none',
  },
  '& .MuiButtonBase-root': {
    padding: 0,
  },
}))
