import * as yup from 'yup'

const alphanumericRegex = /^[a-zA-Z0-9]*$/

export const schema = yup.object().shape({
  code: yup
    .string()
    .required('Esse é um campo obrigatório.')
    .matches(alphanumericRegex, 'Não são permitidos caracteres especiais.'),
})
