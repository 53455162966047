import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import {
  Box,
  Button,
  Carousel,
  FilterDrawer,
  Grid,
  Icon,
  Search,
  usePersistentFilterWithHash,
} from '@stationkim/front-ui'
import { FilterForm } from './components/filterForm'
import { statusChipRender } from './components/utils/statusChipRender'
import { useResaleCompanies } from '../../hooks/useResaleCompanies'
import { useOperators } from '../../hooks/useOperators'
import { UserAttributionFilter } from './components/userAttributionFilter/userAttributionFilter'
import { useResaleUsers } from '../../hooks/useResaleUsers'
import { useFeatures } from '@/main/hooks'

const formDefaultValues = {
  statusPedido: '',
  tipoPedido: '',
  guidsOperadora: '',
  guidEmpresa: '',
  dataCredito: '',
  dataMinima: '',
  dataMaxima: '',
}

export const PurchaseListFilters = ({
  persistentFilterWithHash,
}: {
  persistentFilterWithHash: ReturnType<typeof usePersistentFilterWithHash>
}) => {
  const { features } = useFeatures()
  const formValuesBeforeEdit = useRef(null)
  const { filter, updateFilter } = persistentFilterWithHash
  const { companiesOptions } = useResaleCompanies()
  const { operatorsOptions } = useOperators()
  const { users } = useResaleUsers()
  const [search, setSearch] = useState<string>('')
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const form = useForm()
  const searchHasBeenInitialized = useRef(filter?.campoBusca)

  useEffect(() => {
    form.reset({ ...formDefaultValues, ...filter })
  }, [filter, form])

  const onConfirm = (shouldPersist) => {
    return form.handleSubmit((data) => {
      const itemsWithValues = { ...data }
      Object.keys(itemsWithValues).forEach((key) => {
        if (itemsWithValues[key] === '' || itemsWithValues[key] === undefined || itemsWithValues[key]?.length === 0)
          delete itemsWithValues[key]
      })
      delete itemsWithValues['campoBusca']
      updateFilter({ value: itemsWithValues, persist: shouldPersist, override: true })
      setIsDrawerOpen(false)
    })()
  }

  const onReset = () => {
    form.reset(formDefaultValues)
  }

  const onCancel = () => {
    if (formValuesBeforeEdit.current) form.reset(formValuesBeforeEdit.current)
    formValuesBeforeEdit.current = null
    setIsDrawerOpen(false)
  }

  const renderActiveFilterToChip = () => {
    if (!filter) return null
    const filterWithValue = Object.keys(filter).filter((key) => filter[key] !== '')
    return filterWithValue.map((key) => {
      if (statusChipRender[key])
        return statusChipRender[key]({ filter, updateFilter, companiesOptions, operatorsOptions })
      return null
    })
  }

  useEffect(() => {
    if (filter?.campoBusca !== searchHasBeenInitialized.current && searchHasBeenInitialized.current !== null) {
      searchHasBeenInitialized.current = null
      setSearch(filter?.campoBusca)
    }
  }, [filter])

  return (
    <>
      <Grid container spacing='16px' alignItems='center'>
        <Grid item xs={12} sm={6} md={4}>
          <Search
            placeholder='Pesquisar na tabela abaixo...'
            onSearch={(value) => updateFilter({ value: { campoBusca: value }, persist: false, override: false })}
            value={search}
            onChange={(value) => {
              setSearch(value)
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md='auto'>
          <Button
            variant='softText'
            startIcon={<Icon icon={faFilter} />}
            fullWidth
            onClick={() => {
              setIsDrawerOpen(true)
              formValuesBeforeEdit.current = form.getValues()
            }}
          >
            Filtrar
          </Button>
        </Grid>
        <Grid item xs={12} sm='auto'>
          <Carousel>
            <Box sx={{ display: 'flex', gap: '8px' }}>{renderActiveFilterToChip()}</Box>
          </Carousel>
        </Grid>
        {features.ordersUserFilter && (
          <Grid item xs={12} sm>
            <UserAttributionFilter users={users} />
          </Grid>
        )}
      </Grid>
      <FilterDrawer open={isDrawerOpen} onConfirm={onConfirm} onCancel={onCancel} onClose={onCancel} onReset={onReset}>
        <FilterForm form={form} companiesOptions={companiesOptions} operatorsOptions={operatorsOptions} />
      </FilterDrawer>
    </>
  )
}
