import { useState } from 'react'
import { useAtomValue } from 'jotai'
import { SelectChangeEvent } from '@mui/material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  formatMoney,
  Select,
  Skeleton,
  Typography,
} from '@stationkim/front-ui'
import { AccountPersistentStore } from '@/main/store'
import { ETipoMotivoRemocaoRecarga } from '@/main/enums'
import {
  CardNumber,
  EmployeeNameAndRegistration,
  EmployeeRechargeSummaryContainer,
  Label,
  RechargeValue,
} from './styles'
import { IOrderItem } from '@/presentation/pages/orderTracking/utils/interfaces/iOrderItem'

interface RemoveItemDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: (param: { employeeId: string; deleteReason: ETipoMotivoRemocaoRecarga }) => void
  orderItem?: IOrderItem
  isLoading?: boolean
}

const deleteReasonsOptions = [
  { label: 'Sinalizado pelo cliente', value: ETipoMotivoRemocaoRecarga.SinalizadoPeloCliente },
  { label: 'Reaproveitamento de saldo', value: ETipoMotivoRemocaoRecarga.ReaproveitamentoSaldo },
  { label: 'Cartão não ficou pronto (1ª e 2ª via)', value: ETipoMotivoRemocaoRecarga.CartaoNaoFicouPronto },
]

export const RemoveItemDialog = ({ open, onClose, orderItem, onConfirm, isLoading }: RemoveItemDialogProps) => {
  const account = useAtomValue(AccountPersistentStore)
  const [deleteReason, setDeleteReason] = useState<'' | ETipoMotivoRemocaoRecarga>('')
  const [error, setError] = useState<null | string>(null)
  if (!orderItem) return null

  const onConfirmEmployeeDelete = () => {
    if (deleteReason === '') {
      setError('Selecione um motivo para a exclusão')
      return
    }
    onConfirm({
      deleteReason,
      employeeId: orderItem.employeeId,
    })
  }

  const handleReasonChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value
    if (value !== '') setError(null)
    setDeleteReason(value as ETipoMotivoRemocaoRecarga)
  }

  return (
    <Dialog open={open} disableEscapeKeyDown>
      <DialogTitle sx={(theme) => ({ color: theme.palette.grey[700] })}>Excluir colaborador do pedido</DialogTitle>
      <DialogContent sx={{ margin: '20px 0 48px 0' }} id='order_deleteEmployee_container_employeeData'>
        <Typography variant='body2'>
          Ao excluir o colaborador, o processo de recarga do mesmo será <br />
          cancelado, mesmo se o cartão estiver disponível. O valor usado <br />
          para esta recarga será creditado em seu {account.resale.digitalAccountName}.
        </Typography>
        {isLoading ? (
          <Skeleton variant='rounded' height={150} sx={{ marginTop: '20px' }} />
        ) : (
          <>
            <EmployeeRechargeSummaryContainer>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
                <EmployeeNameAndRegistration>
                  {orderItem.operatorRegistration && (
                    <Typography title={orderItem.operatorRegistration}>{orderItem.operatorRegistration}</Typography>
                  )}
                  <Typography title={orderItem.employeeName}>{orderItem.employeeName}</Typography>
                </EmployeeNameAndRegistration>
                <RechargeValue>
                  Total: R$ <span>{formatMoney(orderItem.total)}</span>
                </RechargeValue>
              </Box>
              <CardNumber>Nº Cartão: {orderItem.cardNumber || 'N/A'}</CardNumber>
            </EmployeeRechargeSummaryContainer>
            <Label>
              Motivo de exclusão<span>*</span>
            </Label>
            <Select
              id='order_deleteEmployee_select_reason'
              options={deleteReasonsOptions}
              value={deleteReason}
              onChange={handleReasonChange}
              required
              error={Boolean(error)}
              helperText={error}
            />
          </>
        )}
      </DialogContent>

      <DialogActions sx={{ gap: '16px' }}>
        <Button
          id='order_deleteEmployee_button_cancel'
          variant='outlined'
          sx={{ filter: 'grayscale(100%)' }}
          fullWidth
          onClick={onClose}
          disabled={isLoading}
        >
          Cancelar
        </Button>
        <Button
          id='order_deleteEmployee_button_confirm'
          variant='contained'
          color='error'
          fullWidth
          onClick={onConfirmEmployeeDelete}
          disabled={isLoading}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
