import api from '@/infra/api'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from '@/main/store'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface IUseOrderMutations {
  config?: {
    [key: string]: {
      onSuccess?: (data?: any) => void
      onError?: (data?: any) => void
    }
  }
}

const invalidateList = (queryClient: ReturnType<typeof useQueryClient>) => {
  queryClient.invalidateQueries({ queryKey: ['newOrders'] })
  queryClient.invalidateQueries({ queryKey: ['purchases'] })
}

export const useOrderMutations = (params: IUseOrderMutations = {}) => {
  const { config } = params
  const notifications = useNotifications()
  const queryClient = useQueryClient()

  const startBrokerOrderProcessing = useMutation({
    mutationFn: ({ orderId }: { orderId: string }) =>
      api.patch(`revenda-pedidos-bff/pedido/processar`, { numeroPedido: orderId }),
    onSuccess: () => {
      notifications.push({ content: 'Atendimento iniciado!', type: EMessageType.Success })
      invalidateList(queryClient)
    },
  })

  const finalizeOrder = useMutation({
    mutationFn: ({ orderId, deliveryCode }: { orderId: string; deliveryCode: string }) =>
      api.post(`resale-bff/orders/${orderId}/finalize?deliveryCode=${deliveryCode}`),
    onSuccess: () => {
      notifications.push({ content: 'Pedido finalizado!', type: EMessageType.Success })
      invalidateList(queryClient)
      config.finalizeOrder.onSuccess && config.finalizeOrder.onSuccess()
    },
  })

  const sendBrokerOrderProjectionFile = useMutation({
    mutationFn: ({ orderId, payload }: { orderId: string; payload: { files: File[] } }) =>
      api.post(`resale-bff/orders/${orderId}/import-balance`, payload),
    onSuccess: (res) => {
      config.sendBrokerOrderProjectionFile.onSuccess && config.sendBrokerOrderProjectionFile.onSuccess(res?.data)
      invalidateList(queryClient)
    },
  })

  //use this only after uploading a balance file
  const calculateBrokerOrderProjection = useMutation({
    mutationFn: ({ orderId }: { orderId: string }) => api.post(`resale-bff/orders/${orderId}/calculate-projection`),
    onSuccess: () => {
      notifications.push({ content: 'Atendimento iniciado!', type: EMessageType.Success })
      invalidateList(queryClient)
    },
  })

  const sendBrokerOrderSlip = useMutation({
    mutationFn: (payload: FormData) => api.patch(`revenda-pedidos-bff/pedido/processarOperadora`, payload),
    onSuccess: () => {
      notifications.push({ content: 'Arquivo enviado com sucesso!', type: EMessageType.Success })
      config.sendBrokerOrderSlip.onSuccess && config.sendBrokerOrderSlip.onSuccess()
      invalidateList(queryClient)
    },
  })

  const sendBrokerOrderPaymentProof = useMutation({
    mutationFn: ({ payload }: { payload: FormData }) => api.patch(`revenda-pedidos-bff/pedido/concluir`, payload),
    onSuccess: () => {
      notifications.push({ content: 'Arquivo enviado com sucesso!', type: EMessageType.Success })
      invalidateList(queryClient)
    },
  })

  return {
    startBrokerOrderProcessing,
    finalizeOrder,
    calculateBrokerOrderProjection,
    sendBrokerOrderSlip,
    sendBrokerOrderPaymentProof,
    sendBrokerOrderProjectionFile,
  }
}
