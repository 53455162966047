import { useState } from 'react'
import { MutationCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useNotifications } from '@/main/hooks'
import { errorHandler } from '@/main/utils/functions/errorHandler'

export const QueryProvider = ({ children }) => {
  const notifications = useNotifications()

  const onError = (error: any, query: any) => {
    const errors = error?.response?.data?.errors || error?.response?.data
    if (!query?.meta?.silent) notifications.push(errorHandler(errors))
  }

  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 30000,
          retry: (failureCount, error: any) => {
            if ([401].includes(error?.response?.status)) {
              return false
            }
            return failureCount <= 1
          },
        },
      },
      mutationCache: new MutationCache({ onError }),
    }),
  )
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
