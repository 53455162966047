import { useContext } from 'react'
import { Theme } from '@mui/material'
import { Box, Card, Divider, formatMoney, Typography } from '@stationkim/front-ui'
import { OrderTrackingContext } from '../orderTracking'

export const RemovedItemsCard = () => {
  const { removedItems, removedItemsReturnedValue } = useContext(OrderTrackingContext)

  return (
    <Card
      sx={(theme: Theme) => ({
        padding: '16px',
        backgroundColor: theme.palette.grey[50],
        marginTop: '16px',
      })}
    >
      <Typography variant='h4'>Remoção de colaboradores</Typography>
      <Typography variant='body2' sx={{ marginTop: '8px' }}>
        Ao remover um colaborador de um pedido, o valor de sua recarga será reduzido do valor final, ou estornado, em
        caso de pedidos pagos.
      </Typography>
      <Divider sx={{ margin: '16px 0' }} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography>Colaboradores removidos:</Typography>
        <Typography sx={{ fontWeight: 600 }}>{removedItems}</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography>Total estornado:</Typography>
        <Typography sx={(theme) => ({ color: theme.palette.success.dark })}>
          - R$: <span style={{ fontWeight: 600 }}>{formatMoney(removedItemsReturnedValue)}</span>
        </Typography>
      </Box>
    </Card>
  )
}
