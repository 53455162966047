import { OrderStatusComponentsProps } from '../orderStatusComponentsProps'
import { Slip } from './slip/slip'
import { Pix } from './pix/pix'
import { ORDER_TYPES } from '@/main/utils/constants'

const paymentTypes = ({ paymentType /*  type */ }: OrderStatusComponentsProps) => {
  switch (paymentType.join(' ')) {
    case 'PIX ACCOUNT_BALANCE':
    case 'ACCOUNT_BALANCE PIX':
    case 'PIX': {
      return <Pix /*  orderType={type} */ />
    }
    default: {
      return <Slip paymentType={paymentType} />
    }
  }
}

export const WaitingPayment = ({ paymentType, orderType }: OrderStatusComponentsProps) => {
  const newOrderType = [ORDER_TYPES.SECOND_COPY].includes(orderType) ? 'cards' : 'recharge'

  return paymentTypes({ paymentType, type: newOrderType })
}
