import { PrimitiveAtom, useAtom } from 'jotai'
import { useEffect } from 'react'

interface IUseFilter {
  filterAtom: PrimitiveAtom<Record<string, any>>
  initialValue?: Record<string, any>
}

const deleteAtomWithHash = (hashName = '') => {
  if (!hashName) return
  const start = hashName
  const regexPattern = `${start}=.*?%7D`
  const regex = new RegExp(regexPattern, 'g')
  const url = new URL(window.location as any)
  url.hash = url.hash.replace(regex, '')
  window.history.replaceState({}, '', url.toString())
}

export const useFilter = ({ filterAtom, initialValue = {} }: IUseFilter) => {
  const [filter, setFilter] = useAtom(filterAtom)
  const resetFilter = (params: { filterValues?: object } = {}) => {
    const { filterValues = {} } = params
    setFilter({ ...initialValue, ...filterValues })
  }

  const updateFilter = (filterValues: object) =>
    setFilter(() => {
      if (filter) return { ...filter, ...filterValues }
      return { ...initialValue, ...filterValues }
    })
  const overrideFilter = (filterValues: object) => setFilter(filterValues)

  useEffect(() => {
    if (typeof filter === 'object') if (Object.keys(filter || []).length === 0) deleteAtomWithHash(filterAtom['name'])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  useEffect(() => {
    Object.keys(initialValue || []).length > 0 && setFilter(initialValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return {
    filter: filter,
    setFilter,
    resetFilter,
    updateFilter,
    overrideFilter,
  }
}
