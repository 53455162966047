import api from '@/infra/api'
import { useMutation } from '@tanstack/react-query'

export const useAddCardToItem = () => {
  const updateCardInfo = useMutation({
    mutationKey: ['addCardToItem'],
    mutationFn: ({
      cardNumber,
      employeeGuid,
      orderId,
    }: {
      cardNumber: string
      employeeGuid: string
      orderId: string
    }) =>
      api.put('/revenda-pedidos-bff/pedido/cartoes', {
        idPedido: orderId,
        idColaborador: employeeGuid,
        numero: cardNumber,
      }),
  })

  return {
    updateCardInfo,
  }
}
