import { formFieldsConfiguration } from '@/main/utils/formFieldsConfiguration/formFieldsConfiguration'
import { IUseCompanyData, useCompanyActivityBranches, useModifyCompany } from '@/main/hooks'
import { Form, FormField, FormHeader } from '@/presentation/components/organisms'
import { useContext, useEffect, useState } from 'react'
import { CompanyGeneralDataContext } from '../../companyGeneralData'
import { EMessageType } from '@/main/store'
import { useForm } from 'react-hook-form'
import { NUMBER_OF_BENEFICIARIES } from '@/main/utils/constants'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'

export const GeneralDataForm = () => {
  const { resources } = useAccessProfile({ resource: 'empresa' })

  const { company, companyId, notification, queryClient, isLoadingCompany } = useContext(CompanyGeneralDataContext)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const form = useForm<any>({
    defaultValues: company,
    shouldFocusError: true,
    mode: 'onChange',
  })

  useEffect(() => {
    !isLoadingCompany &&
      form.reset({
        ...company,
        numberBeneficiaries: NUMBER_OF_BENEFICIARIES[company?.numberBeneficiaries],
      })
  }, [isLoadingCompany])

  const modifyCompany = useModifyCompany({
    config: {
      generalData: {
        onSuccess: () => {
          notification.push({ content: 'Dados gerais editados com sucesso!', type: EMessageType.Success })
          queryClient.invalidateQueries({ queryKey: ['company', companyId] })
          setIsEditing(false)
        },
      },
    },
  })
  const { branches: activityBranchesOptions } = useCompanyActivityBranches()

  const isLoading = isLoadingCompany || modifyCompany.generalData.isLoading

  const handleGeneralDataSubmit = (data: IUseCompanyData) => {
    modifyCompany.generalData.mutate({
      corporateName: data.corporateName,
      tradeName: data.tradeName,
      municipalRegistration: data.municipalRegistration,
      stateRegistration: data.stateRegistration,
      activyBranch: data.activityBranch,
      allowDefaultDepartmentOrder: data.allowDefaultDepartmentOrder,
    })
  }

  return (
    <Form
      form={form}
      onSubmit={(data) => handleGeneralDataSubmit(data)}
      isLoading={isLoading}
      isEditable={isEditing}
      onEditButtonClick={() => setIsEditing(true)}
      onCancel={() => setIsEditing(false)}
    >
      <FormHeader title='Dados Gerais da Empresa' hasNotPermission={resources({ item: 'empresa_gerenciar' })} />
      <FormField
        type='maskedInput'
        name='cnpj'
        gridProps={{ xs: 12, md: 4 }}
        {...formFieldsConfiguration.company.cnpj}
        rules={formFieldsConfiguration.company.cnpj.rules({ isRequired: true })}
        blocked={true}
      />
      <FormField
        type='input'
        name='code'
        gridProps={{ xs: 12, sm: 6, md: 4 }}
        {...formFieldsConfiguration.company.code}
        blocked={true}
      />
      <FormField
        type='input'
        name='integrationCode'
        gridProps={{ xs: 12, sm: 6, md: 4 }}
        {...formFieldsConfiguration.company.integrationCode}
        blocked={true}
      />
      <FormField
        type='input'
        name='corporateName'
        gridProps={{ xs: 12, md: 4 }}
        inputProps={{ maxLength: 90 }}
        {...formFieldsConfiguration.company.corporateName}
        rules={formFieldsConfiguration.company.corporateName.rules({ isRequired: true })}
      />
      <FormField
        type='input'
        name='tradeName'
        gridProps={{ xs: 12, md: 4 }}
        {...formFieldsConfiguration.company.tradeName}
        rules={formFieldsConfiguration.company.tradeName.rules({ isRequired: true })}
        inputProps={{ maxLength: 40 }}
      />
      <FormField
        type='maskedInput'
        name='stateRegistration'
        gridProps={{ xs: 12, sm: 6, md: 4 }}
        {...formFieldsConfiguration.company.stateRegistration}
        rules={formFieldsConfiguration.company.stateRegistration.rules({ isRequired: true })}
      />
      <FormField
        type='input'
        name='municipalRegistration'
        gridProps={{ xs: 12, sm: 6, md: 4 }}
        inputProps={{ maxLength: 11 }}
        {...formFieldsConfiguration.company.municipalRegistration}
        rules={formFieldsConfiguration.company.municipalRegistration.rules()}
      />
      <FormField
        type='select'
        name='activityBranch'
        gridProps={{ xs: 12, md: 4 }}
        options={activityBranchesOptions || []}
        getOptionValue={(option: any) => option.code}
        getOptionLabel={(option: any) => option.description}
        {...formFieldsConfiguration.company.activityBranch}
        rules={formFieldsConfiguration.company.activityBranch.rules({ isRequired: true })}
      />
      <FormField
        type='date'
        name='creationDate'
        gridProps={{ xs: 12, sm: 6, md: 4, lg: 2 }}
        label='Data de cadastro'
        placeholder='00/00/0000'
        blocked={true}
      />
      <FormField
        type='date'
        name='lastOrderDate'
        gridProps={{ xs: 12, sm: 6, md: 4, lg: 2 }}
        label='Último Pedido'
        placeholder='00/00/0000'
        blocked={true}
      />
      <FormField
        type='input'
        name='numberBeneficiaries'
        gridProps={{ xs: 12, sm: 6, md: 4 }}
        label='N° de beneficiários'
        placeholder='00 - 00'
        blocked={true}
      />
      <FormField
        type='input'
        name='employeesCount'
        gridProps={{ xs: 12, sm: 6, md: 4 }}
        label='N° de colaboradores'
        placeholder='00 - 00'
        blocked={true}
      />
      <FormField
        type='checkbox'
        name='allowDefaultDepartmentOrder'
        gridProps={{ xs: 12 }}
        label='Permitir recarga para colaboradores sem departamento'
      />
    </Form>
  )
}
