import { createContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Divider, Grid } from '@/presentation/components/atoms'
import { useCompany, useModifyCompany, useNotifications } from '@/main/hooks'
import { MissingDataDialog } from './utils/dialogs/missingDataDialog'
import { ReproveDialog } from './utils/dialogs/reproveDialog'
import { COMPANY_STATUS } from '@/main/utils/constants'
import { useModifyCompanyConfig } from './utils/useModifyCompanyConfig'
import { AddressesForm, CompanyDocuments, ContactsForm, GeneralDataForm } from './utils/forms'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'

export const CompanyGeneralDataContext = createContext<any>(null)

export const CompanyGeneralData = () => {
  const { resources } = useAccessProfile({ resource: 'empresa' })
  const { company, isLoading: isLoadingCompany } = useCompany()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const notification = useNotifications()
  const modifyCompany = useModifyCompany(
    useModifyCompanyConfig({ notification, navigate, queryClient, companyId: company?.id }),
  )
  const [isMissingDataDialogOpen, setIsMissingDataDialogOpen] = useState<number | null>(null)
  const [isReproveDialogOpen, setIsReproveDialogOpen] = useState(false)
  const isWaitingApproval = company?.status === COMPANY_STATUS.WAITING_APPROVAL

  const handleApprove = () => {
    if (
      !Array.isArray(company?.administrateFees) ||
      !Array.isArray(company?.reuseFees) ||
      !Array.isArray(company?.deliveryFees)
    )
      setIsMissingDataDialogOpen(2)

    const deskFees = [...company.administrateFees, ...company.reuseFees, ...company.deliveryFees]

    if (!(deskFees.length > 0) && !(company?.operatorsCard.length > 0)) setIsMissingDataDialogOpen(1)
    else if (!(deskFees.length > 0)) setIsMissingDataDialogOpen(2)
    else if (!(company?.operatorsCard.length > 0)) setIsMissingDataDialogOpen(3)
    else {
      modifyCompany?.approve.mutate({
        benefits: company?.benefits,
        cei: company?.cei,
        slipFee: company?.slipFee,
        successFee: company?.successFee,
        deskFees,
      })
    }
  }

  const handleReprove = () => {
    setIsReproveDialogOpen(true)
  }

  const handleMissingDataDialogClose = () => {
    setIsMissingDataDialogOpen(null)
  }

  const handleReproveConfirm = () => modifyCompany?.reprove.mutate()
  const handleReproveCancel = () => setIsReproveDialogOpen(false)

  return (
    <CompanyGeneralDataContext.Provider
      value={{
        company,
        companyId: company?.id,
        notification,
        navigate,
        queryClient,
        isLoadingCompany,
      }}
    >
      <GeneralDataForm />
      <Divider overflow sx={{ margin: '32px 0 16px 0' }} />
      <AddressesForm />
      <Divider overflow sx={{ margin: '32px 0 16px 0' }} />
      <ContactsForm />
      <CompanyDocuments documents={company?.documents} />

      {isWaitingApproval && (
        <>
          <Divider overflow sx={{ margin: '32px 0 16px 0' }} />
          <Grid container spacing='16px' justifyContent='flex-end' sx={{ marginTop: '16px' }}>
            {resources({ item: 'empresa_gerenciar' }) ? (
              <>
                <Grid item xs={12} sm={6} md='auto'>
                  <Button variant='outlined' fullWidth onClick={handleReprove}>
                    Negar empresa
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md='auto'>
                  <Button variant='contained' fullWidth onClick={handleApprove}>
                    Aprovar
                  </Button>
                </Grid>
              </>
            ) : null}
          </Grid>
        </>
      )}
      <MissingDataDialog open={isMissingDataDialogOpen} onClose={handleMissingDataDialogClose} />
      <ReproveDialog open={isReproveDialogOpen} onClose={handleReproveCancel} onConfirm={handleReproveConfirm} />
    </CompanyGeneralDataContext.Provider>
  )
}
