import { styled } from '@mui/material'
import { Box, Typography } from 'presentation/components/atoms'

export const ItemHeader = styled(Box)(({ theme }) => ({
  padding: '4px 8px',
  backgroundColor: theme.palette.grey[50],
  height: '32px',
  display: 'flex',
  gap: '32px',
  borderRadius: '4px',
  justifyContent: 'space-between',
}))

export const ItemValuesContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  textAlign: 'right',
  height: '100%',
  justifyContent: 'space-between',
  padding: '12px 8px',
  '&:last-of-type': {
    textAlign: 'left',
  },
}))

export const ItemLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.grey[700],
}))
