import api from '@/infra/api'
import { ETipoMotivoRemocaoRecarga } from '@/main/enums'
import { useMutation, UseMutationResult } from '@tanstack/react-query'

interface UseRemoveFromORder {
  removeItem: UseMutationResult
}

export const useRemoveFromOrder = (): UseRemoveFromORder => {
  const removeItem = useMutation({
    mutationKey: ['removeItemFromOrder'],
    mutationFn: ({
      deleteReason,
      employeeId,
      orderId,
    }: {
      deleteReason: ETipoMotivoRemocaoRecarga
      employeeId: string
      orderId: string
    }) =>
      api.patch('/revenda-pedidos-bff/pedido/removerColaborador', {
        idPedido: orderId,
        idItem: employeeId,
        motivoRemocaoRecarga: deleteReason,
      }),
  })

  return {
    removeItem,
  }
}
