import { AvatarGroup, Box } from '@stationkim/front-ui'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { SelectionMenu } from './components/selectionMenu/selectionMenu'
import { StyledAvatar } from './styles'

export type TUser = {
  id: string
  name: string
  src: string
  notificationCount: number
}

export interface UserAttributionFilterProps {
  users: TUser[]
}

const AVATAR_SIZE = 40

const userClick = (userId: string, setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>) => {
  setSelectedUsers((selected) => {
    const userIndex = selected.indexOf(userId)
    if (userIndex < 0) return [...selected, userId]
    const newSelected = [...selected]
    newSelected.splice(userIndex, 1)
    return newSelected
  })
}

export const UserAttributionFilter = ({ users }: UserAttributionFilterProps) => {
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]) //passar via props
  const [maxAvatars, setMaxAvatars] = useState<null | number>(null)
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(menuAnchor)
  const containerRef = useRef(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget)
  }

  const renderUsersAvatars = useCallback(
    (usersToRender: TUser[]) => {
      if (!usersToRender) return []
      return usersToRender.map((user) => (
        <StyledAvatar
          key={user.id}
          name={user.name}
          src={user.src}
          notificationCount={user.notificationCount}
          sx={{ cursor: 'pointer' }}
          active={selectedUsers.includes(user.id)}
          onClick={() => {
            userClick(user.id, setSelectedUsers)
          }}
        />
      ))
    },
    [selectedUsers],
  )

  const usersToRender = useMemo(() => renderUsersAvatars(users), [users, renderUsersAvatars])

  const setVisibleAvatars = () => {
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect()
      const maxAvatars = Math.floor(containerRect.width / AVATAR_SIZE)
      setMaxAvatars(maxAvatars)
    }
  }

  const resizeObserver = useMemo(() => {
    return new ResizeObserver(() => {
      setVisibleAvatars()
    })
  }, [])

  useEffect(() => {
    resizeObserver.observe(containerRef.current)
  }, [resizeObserver])

  return (
    <Box ref={containerRef} onResize={setVisibleAvatars}>
      <AvatarGroup
        total={users.length}
        max={maxAvatars}
        slotProps={{
          additionalAvatar: {
            onClick: handleClick as any,
            sx: { cursor: 'pointer' },
          },
        }}
      >
        {usersToRender}
      </AvatarGroup>
      <SelectionMenu
        anchorEl={menuAnchor}
        open={isMenuOpen}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        users={users}
        onClose={() => {
          setMenuAnchor(null)
        }}
      />
    </Box>
  )
}
