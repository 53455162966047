import { styled, Theme } from '@mui/material'
import { Box } from '@stationkim/front-ui'

const variants = (theme: Theme) => ({
  default: {
    background: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  warning: {
    background: theme.palette.warning.main,
    color: theme.palette.common.white,
  },
  empty: {
    background: theme.palette.grey[100],
    color: theme.palette.grey[900],
    fontWeight: 600,
  },
})

export const Counter = styled(Box)<{ variant?: keyof ReturnType<typeof variants> }>(
  ({ theme, variant = 'default' }) => ({
    borderRadius: '50%',
    marginLeft: 'auto',
    height: '22px',
    minHeight: '22px',
    width: '22px',
    minWidth: '22px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '.875rem',
    ...variants(theme)[variant],
  }),
)
