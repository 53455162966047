import React from 'react'
import { ReadFieldContainer, ReadFieldTitle, ReadFieldValue } from './styles'

function ReadField({ name, children, ...props }) {
  return (
    <ReadFieldContainer {...props}>
      <ReadFieldTitle>{name}</ReadFieldTitle>
      <ReadFieldValue>{children}</ReadFieldValue>
    </ReadFieldContainer>
  )
}

export default ReadField
