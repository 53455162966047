import api from '@/infra/api'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useMemo } from 'react'

type TCompany = {
  id: string
  nomeFantasia: string
  cnpj: string
  guid: string
  codigo: string
}

export const useResaleCompanies = () => {
  const { data, isLoading } = useQuery<AxiosResponse<TCompany[]>>({
    queryKey: ['resaleCompaniesSelect'],
    queryFn: () => api.get('/revenda-pedidos-bff/pedido/empresas'),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const organizedData = useMemo(() => {
    if (!data) return []
    return data?.data?.map((company) => ({
      label: company.nomeFantasia,
      value: company.guid,
    }))
  }, [data])

  return {
    companiesOptions: organizedData,
    isLoading,
  }
}
