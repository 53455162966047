import { IMessage, EMessageType } from '@/main/store'

interface IApiError {
  code: string
  mensagem: string
  type: EMessageType
}

interface ErrorHandlerProps {
  error: string | Array<IApiError>
}
export const errorHandler = (error: ErrorHandlerProps): IMessage | Array<IMessage> => {
  const basicError = { title: 'Erro', content: 'Algo inesperado aconteceu!', type: EMessageType.Error }

  if (typeof error === 'string') return { ...basicError, content: error }
  else if (Array.isArray(error))
    return error.map((err: any) => ({
      title: err.code || 'Erro',
      content: err.mensagem,
      type: err.type || err.tipo,
    }))
  return basicError
}
