import { styled } from '@mui/material'
import { Avatar } from '@stationkim/front-ui'

export const StyledAvatar = styled(Avatar)<{ active: boolean }>(({ theme, active }) => ({
  borderColor: active ? theme.palette.primary.light + ' !important' : '',
  zIndex: active ? 1 : 'initial',
  transform: active ? 'scale(1.1)' : 'initial',
  transition: 'all .1s linear',
  '&:hover': {
    zIndex: 1,
    transform: 'scale(1.1)',
  },
}))
