import { useContext } from 'react'
import _ from 'lodash'
import { Box, Grid, Tooltip, Typography } from 'presentation/components/atoms'
import { Item, ItemHeader, ItemLabel, ItemValuesContainer } from './styles'
import { formatMoney } from 'main/utils/functionUtils'
import { TOrderItem } from 'main/interfaces'
import { OrderTrackingContext } from '@/presentation/pages/orderTracking/orderTracking'
import { BENEFIT_TYPE } from '@/main/utils/constants'
import { InfoCircle } from '@/presentation/assets/icons/InfoCircle'

const GridValue = ({
  label,
  value,
  isMonetary,
  blocked,
}: {
  blocked?: boolean
  label: string
  value: string | number
  isMonetary?: boolean
}) => {
  return (
    <Grid item xs='auto' key={_.uniqueId()}>
      <ItemValuesContainer>
        <ItemLabel variant='text2' sx={{ whiteSpace: 'nowrap' }}>
          {label}
        </ItemLabel>
        <Typography variant='label2' sx={{ fontWeight: 400, whiteSpace: 'nowrap' }} id='value'>
          {isMonetary ? `R$ ${formatMoney(Number(value))}` : value}
          {blocked ? (
            <Tooltip
              placement='top'
              title={
                <>
                  <p>
                    O valor que seria utilizado para esta recarga foi reembolsado. <br />
                    Motivo: O cartão selecionado encontra-se com status <b>Bloqueado.</b>
                  </p>
                </>
              }
            >
              <div>
                <InfoCircle />
              </div>
            </Tooltip>
          ) : null}
        </Typography>
      </ItemValuesContainer>
    </Grid>
  )
}

export const OrderItem = ({ orderItem }: { orderItem: TOrderItem }) => {
  const { isRecharge } = useContext(OrderTrackingContext)

  return (
    <Box>
      <ItemHeader>
        <Typography>{orderItem.employeeRegistration}</Typography>
        <Typography>{orderItem.employeeName}</Typography>
      </ItemHeader>
      <Item
        blocked={orderItem?.status === 12 || orderItem?.status === 13}
        container
        spacing='8px'
        justifyContent='space-between'
      >
        {isRecharge ? (
          <GridValue label='Produto' value={BENEFIT_TYPE[orderItem.benefitType]} />
        ) : (
          <GridValue label='Nome Cartão' value={orderItem.operatorCardName} />
        )}
        <GridValue label='N° Cartão' value={orderItem.cardNumber} />
        <GridValue label='Mat. Operadora' value={orderItem.operatorRegistration} />
        {isRecharge ? (
          <>
            <GridValue label='Dias' value={orderItem.days} />
            <GridValue label='Valor dia' value={orderItem.dayValue} isMonetary />
          </>
        ) : (
          <GridValue label='Operadora' value={orderItem.operatorName} />
        )}
        <GridValue
          label='Total'
          value={orderItem.total}
          isMonetary
          blocked={orderItem?.status === 12 || orderItem?.status === 13}
        />
      </Item>
    </Box>
  )
}
