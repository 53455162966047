import { EStatusCompra, EStatusPedido } from '@/main/enums'
import { useTheme } from '@mui/material'
import { useMemo } from 'react'
import { StyledChip } from './styles'
import { constrictPurchaseStatus, getColors, orderStatusChipMap, purchaseStatusChipMap } from './utils/functions'

interface StatusChipProps {
  purchaseStatus?: EStatusCompra
  orderStatus?: EStatusPedido
}

export const StatusChip = ({ orderStatus, purchaseStatus }: StatusChipProps) => {
  const theme = useTheme()
  const colors = useMemo(() => getColors(theme), [theme])
  const isPurchase = purchaseStatus !== undefined

  const statusInfo = isPurchase
    ? purchaseStatusChipMap({
        colors,
        purchaseStatus: constrictPurchaseStatus({ purchaseStatus }),
      })
    : orderStatusChipMap({ colors, orderStatus })

  return <StyledChip {...statusInfo} />
}
