import { AxiosResponse } from 'axios'
import { UseInfiniteQueryResult } from '@tanstack/react-query'
import { IApiResponse, IPurchase } from '../useOrderList'
import { EStatusCompra } from '@/main/enums'
import { ignoreUTCTimeOnDateString } from '@/main/utils/functions'

export const organizePurchasesData = (
  rawData: UseInfiniteQueryResult<AxiosResponse<IApiResponse>>['data']['pages'],
): IPurchase[] => {
  return rawData.reduce((organizedData, compras) => {
    const purchases = compras.data.valor.compras.map((compra): IPurchase => {
      return {
        clientCode: compra.codigoCliente,
        companyTradeName: compra.nomeEmpresaFantasia,
        id: compra.idCompra,
        purchaseOrdersTypes: compra.tipoPedido || [],
        purchaseFeesTotal: compra.totalTaxas,
        purchaseNumber: compra.statusCompra === EStatusCompra.Carrinho ? 'Rascunho' : compra.codigoCompra,
        purchaseRechargeValue: compra.valorRecarga,
        purchaseValue: compra.valor,
        paymentMethod: compra.tiposPagamento,
        pendency: compra.pendencias,
        purchaseStatus: compra.statusCompra,
        purchaseComplementaryValue: compra.valorComplementar,
        creditDate: compra.dataCredito
          ? new Date(ignoreUTCTimeOnDateString(compra.dataCredito)).toLocaleDateString('pt-BR', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
          : null,
        purchaseDate: new Date(compra.dataCriacao).toLocaleDateString(),
        purchaseEndDate: new Date(compra.dataLiquidacao).toLocaleDateString(),
        slipDueDate: new Date(compra.dataVencimentoBoleto).toLocaleDateString(),
        responsibleUser: compra.responsavel,
        situation: '',
        children: [
          {
            id: compra.idCompra + '_orders',
            content: compra.pedidos.map((pedido) => ({
              orderNumber: pedido.numeroPedido,
              orderStatus: pedido.statusPedido,
              purchaseStatus: compra.statusCompra,
              id: pedido.idPedido,
              operator: pedido.operadoraPedido,
              totalValue: pedido.valorPedido,
              pendency: '',
              responsibleUser: '',
              orderDate: new Date(compra.dataCriacao).toLocaleDateString(),
              creditDate: compra.dataCredito ? new Date(compra.dataCredito).toLocaleDateString() : null,
              totalItensInOrder: pedido.quantidadeItensUnicosPedido,
              operatorSite: pedido.siteOperadora,
              operatorEmail: pedido.contatoOperadora,
              operatorUser: pedido.usuarioOperadora,
              operatorPassword: pedido.senhaOperadora,
              companyId: compra.guidEmpresa,
              purchaseId: compra.idCompra,
              orderType: pedido.tipoPedido,
            })),
          },
        ],
      }
    })
    return [...organizedData, ...purchases]
  }, [])
}
