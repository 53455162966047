import { useEffect } from 'react'

import { useNotifications } from '@/main/hooks'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'
import { EMessageType } from '@/main/store'
import { useNavigate } from 'react-router-dom'
import { useAtomValue } from 'jotai'
import { companyTab } from '../consultCompany'

export const useWarning = ({ value }: { value: number }) => {
  const { resources } = useAccessProfile({ resource: 'empresa' })
  const notification = useNotifications()
  const navigate = useNavigate()
  const tab = useAtomValue(companyTab)

  const tabs = {
    0: 'empresa_visualizar',
    1: 'empresa_parametros_gerais',
    2: 'empresa_visualizar_colaborador',
    3: 'empresa_visualizar_cartoes',
    4: 'empresa_visualizar_beneficios',
  }

  useEffect(() => {
    if (!resources({ item: tabs[tab || value] })) {
      navigate('/')

      return notification.push({
        content: 'O usuário não tem permissão para acessar a funcionalidade',
        type: EMessageType.Warning,
      })
    }
  }, [value])

  return null
}
