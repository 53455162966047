export const featureFlags = {
  development: {
    ticketingBalance: true,
    dashboard: true,
    dashboardEarningsChart: true,
    dashboardOrdersChart: true,
    dashboardOrdersTable: true,
    dashboardFinancialTable: true,
    dashboardBalanceProjectionGrid: true,
    digitalAccountExtract: true,
    paymentsConciliation: true,
    dashboardFinancialStation: true,
    ordersUserFilter: true,
  },
  stage: {
    ticketingBalance: true,
    dashboard: true,
    dashboardEarningsChart: true,
    dashboardOrdersChart: true,
    dashboardOrdersTable: true,
    dashboardFinancialTable: true,
    dashboardBalanceProjectionGrid: true,
    digitalAccountExtract: true,
    paymentsConciliation: true,
    dashboardFinancialStation: true,
    ordersUserFilter: false,
  },
  production: {
    ticketingBalance: false,
    dashboard: true,
    dashboardEarningsChart: true,
    dashboardOrdersChart: true,
    dashboardOrdersTable: true,
    dashboardFinancialTable: true,
    dashboardBalanceProjectionGrid: true,
    digitalAccountExtract: true,
    paymentsConciliation: true,
    dashboardFinancialStation: true,
    ordersUserFilter: false,
  },
}
